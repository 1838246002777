import { graphQLQuery } from "../../Services/GraphQL";

export const gqlLocations : graphQLQuery = {
  query: `query {
    locations {
      id
      parentId
      name
      dialNumber
      type
    }
  }`,
  errorMessage: "Error loading locations.",
  collection: "locations"
};

export const gqlLocation : graphQLQuery = {
  query: `query locations($locationId: [UUID!]) {
    locations(ids: $locationId) {
      id
      parentId
      name
      dialNumber
      type
      site {id }
      organizations { id }
    }
  }`,
  errorMessage: "Error loading location.",
  collection: "locations"
};

export const gqlOrganizations: graphQLQuery = {
  query: `query organizations {
    organizations {
      id
      name
      sites { id }
      parentId
    }
  }`,
  errorMessage: "Error loading organizations.",
  collection: "organizations",
};

export const gqlQueryLocationDependencies: graphQLQuery = {
  query: `query locations($locationIds: [UUID!]) {
    locations(ids: $locationIds) {
      id
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading location dependencies.",
  collection: "locations"
};

export const gqlDeleteLocation : graphQLQuery = {
  query: `mutation deleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      location {
        id
      }
    }
  }`,
  errorMessage: "Error deleting location.",
  collection: "deleteLocation.location"
};

export const gqlUpdateLocation : graphQLQuery = {
  query: `mutation updateLocation($id: UUID!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      location {
        id
        name
        dialNumber
        type
        site {id }
        organizations { id }
      }
    }
  }`,
  errorMessage: "Error loading location.",
  collection: "updateLocation.location"
};

export const gqlAddLocation : graphQLQuery = {
  query: `mutation addLocation($input: AddLocationInput!) {
    addLocation(input: $input) {
      location {
        id
        name
        dialNumber
        type
        site {id }
        organizations { id }
      }
    }
  }`,
  errorMessage: "Error loading location.",
  collection: "addLocation.location"
};

export const gqlLocationIdentities : graphQLQuery = {
  query: `query locations($locationId: [UUID!]) {
    locations(ids: $locationId) {
      identities {
        system
        value
      }
    }
  }`,  
  errorMessage: "Error loading location.",
  collection: "locations"
};

export const gqlLocationIdentitiesConcurrencyCheck : graphQLQuery = {
  query: `query locations($identity: IdentityFilter) {
    locations(identities: $identity) {
      id
      identities {
        system
        value
      }
    }
  }`,  
  errorMessage: "Error loading location.",
  collection: "locations"
};

export const gqlAddLocationIdentity : graphQLQuery = { 
  query: `mutation addLocationIdentity($input: AddIdentityInput!) {
    addLocationIdentity(input: $input) {
      location {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading location.",
  collection: "addLocationIdentity.location"
};

export const gqlRemoveLocationIdentity : graphQLQuery = { 
  query: `mutation removeLocationIdentity($input: RemoveIdentityInput!) {
    removeLocationIdentity(input: $input) {
      location {
        id
        identities {
          system
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading location.",
  collection: "removeLocationIdentity.location"
};

export const gqlLocationSystems : graphQLQuery = {
  query: `query {
    locationIdentitySystems { system }
  }`,  
  errorMessage: "Error loading location systems.",
  collection: "locationIdentitySystems"
};

export const gqlLocationTypes : graphQLQuery = {
  query: `query {
    locationTypes { type }
  }`,  
  errorMessage: "Error loading location types.",
  collection: "locationTypes"
};