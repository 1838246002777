import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import { useState } from "react";
import LocationIcon from "../../assets/icons/ic_locations_buildings_24px.svg";
import Tabs from "devextreme-react/tabs";
import TreeView, { Item, SearchEditorOptions } from "devextreme-react/tree-view";
import { gqlLocation, gqlLocations } from "./LocationQuery";
import { query } from "../../Services/GraphQL";
import { ISelectableLocation } from "./ILocation";

export const LocationPilet: Pilet = {
  name: "Location Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "Locations",
      () => (
        <Link to="/locations">
          <div>
            <div>
              <img src={LocationIcon}></img>
            </div>
            <div>Locations</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 2, location: "/locations" }
    );

    api.registerTile(
      "Locations",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + LocationIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/locations">Locations</Link>
              <br />
              Administration of locations.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    api.registerPage("/locations", ({ children }) => {
      const [locations, setLocations] = useState<ISelectableLocation[]>([]);

      const refreshData = async () => {
        const result = await query<ISelectableLocation[]>(gqlLocations);
        result.forEach((loc) => {
          loc.selected = false;
        });
        setLocations(result);
      };

      const refreshSingleData = async (locationId) => {
        const result = await query<ISelectableLocation[]>(gqlLocation, { locationId: [locationId] });
        var location = { ...result[0], selected: true };

        setLocations((locs) => {
          const updatedLocations = locs.map((loc) => {
            if (loc.id === locationId) {
              return location;
            }
            return loc;
          });

          return updatedLocations;
        });
      };

      const addSingleData = async (locationId) => {
        const result = await query<ISelectableLocation[]>(gqlLocation, { locationId: [locationId] });
        var location = { ...result[0], selected: true };

        setLocations((locs) => {
          const updatedLocations = locs.map((loc) => {
            if (loc.selected) {
              return { ...loc, selected: false };
            }
            return loc;
          });

          return [...updatedLocations, location];
        });
      };

      const removeSingleData = async (locationId) => {
        setLocations((locs) => {
          const updatedLocations = locs.filter((loc) => loc.id !== locationId);

          return updatedLocations;
        });
      };

      const handleNodeClick = async (e) => {
        setLocations((locs) => {
          const updatedLocations = locs.map((loc) => {
            if (loc.id === e.itemData.id) {
              return { ...loc, selected: true };
            }

            if (loc.selected) {
              return { ...loc, selected: false };
            }

            return loc;
          });

          return updatedLocations;
        });
        api.setData("selectedLocation", e.itemData.id);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "location") {
            if (data.value && data.action === "update") {
              refreshSingleData(data.value);
            } else if (data.value && data.action === "add") {
              addSingleData(data.value);
            } else if (data.value && data.action === "delete") {
              removeSingleData(data.value);
            } else {
              refreshData();
            }
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
            api.setData("selectedLocation", "");
          }
        });
      }, []);

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ margin: "1rem 1rem 1rem 1rem", fontSize: 24, opacity: 0.8 }}>Locations</div>
            <div style={{ display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <TreeView
                  dataSource={locations}
                  ref={(ref) => {
                    this.treeView = ref;
                  }}
                  dataStructure="plain"
                  keyExpr="id"
                  parentIdExpr="parentId"
                  selectionMode="multiple"
                  displayExpr="name"
                  selectedExpr="selected"
                  selectNodesRecursive={false}
                  searchEnabled={true}
                  showCheckBoxesMode="none"
                  searchMode="contains"
                  onItemClick={handleNodeClick}
                >
                  <SearchEditorOptions stylingMode="outlined"></SearchEditorOptions>
                </TreeView>
              </div>
              <div className="extension-list">
                <api.Extension name="extension-list" params={{ extensions: "location" }}></api.Extension>
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
