import { PiletApi } from "piral-base";

export interface ISetting {
  id: string;
  settingKey: string;
  value: string;
  operation: SettingOperation;
}

export enum SettingOperation {
  Add,
  Remove,
  None,
}

export interface IAddSettingsModalOptions {
  addSetting: (setting: ISetting) => boolean;
  onConcurrencyError?: (setting: ISetting) => Promise<boolean>;
  systems: string[];
}

export interface SettingListProps {
  systems: string[];
  entityId: string | null | undefined;
  settings: ISetting[];
  onSave?: (settings: ISetting[]) => void;
  onCancel?: () => void;
  onConcurrencyError?: (setting: ISetting) => Promise<boolean>;
  api?: PiletApi;
}
