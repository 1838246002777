import { graphQLQuery } from "../../Services/GraphQL";

/**
 * Utility method to return a query which returns the name(s) of entities of a specified type of a list of given entity IDs.
 * @param entityType The entity type, e.g. "device"; "location"; "organization"
 * @returns A graphQL query to return names of the given entities.
 */
export function gqlQueryEntityName(entityType: string): graphQLQuery {
  const entityTypePlural = entityType + "s";
  const idsThing = `${entityType}Ids`;
  return {
    query: `query ${entityTypePlural}($${idsThing}: [UUID!]) {
        ${entityTypePlural}(ids: $${idsThing}) {
          id,
          name
        }
      }`,
    errorMessage: "Error loading entity names.",
    collection: entityTypePlural,
  };
}
