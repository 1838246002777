import { graphQLQuery } from "../../Services/GraphQL";

export const gqlLocations: graphQLQuery = {
  query: `query locations {
    locations {
      id
      name
      site { id }
      parentId
    }
  }`,
  errorMessage: "Error loading locations.",
  collection: "locations",
};

export const gqlModels: graphQLQuery = {
  query: `query models {
    models {
      id
      name
    }
  }`,
  errorMessage: "Error loading models.",
  collection: "models",
};

export const gqlQueryPaginatedDevices: graphQLQuery = {
  query: `query paginatedDevices($first: Int!, $after: String!, $searchText: String, $sortOrder: [DeviceSortInput!]) {
    paginatedDevices(first: $first, after: $after, searchText: $searchText, order: $sortOrder) {
      nodes {
        id
        name
        externalId
        model {
          id
          name
          manufacturer { name }
          deviceType { name }
        }
        location { name }
      },
      pageInfo { hasNextPage, startCursor, endCursor},
      totalCount
    }
  }`,
  errorMessage: "Error loading paginated devices.",
  collection: "paginatedDevices",
};

export const gqlQueryDevices: graphQLQuery = {
  query: `query {
    devices {
      id
      name
      externalId
      model {
        id
        name
        manufacturer { name }
        deviceType { name }
      }
      location { name }
    }
  }`,
  errorMessage: "Error loading devices.",
  collection: "devices",
};

export const gqlQueryDevice: graphQLQuery = {
  query: `query devices($deviceIds: [UUID!]) {
    devices(ids: $deviceIds) {
      id
      name
      externalId
      model {
        id
        name
        manufacturer { name }
        deviceType { name }
      }
      site { id name }
      location { id name }
    }
  }`,
  errorMessage: "Error loading device.",
  collection: "devices",
};

export const gqlQueryDeviceDependencies: graphQLQuery = {
  query: `query devices($deviceIds: [UUID!]) {
    devices(ids: $deviceIds) {
      id
      name
      dependencies { system, criticality }
    }
  }`,
  errorMessage: "Error loading device dependencies.",
  collection: "devices",
};

export const gqlUpdateDevice: graphQLQuery = {
  query: `mutation updateDevice($id: UUID!, $input: UpdateDeviceInput!) {
    updateDevice(id: $id, input: $input) {
      device {
        id
        name
        externalId
        model {
          id
          name
          manufacturer { name }
          deviceType { name }
        }
        site { id name }
        location { id name }
      }
    }
  }`,
  errorMessage: "Error updating device.",
  collection: "updateDevice.device",
};

export const gqlDeleteDevice: graphQLQuery = {
  query: `mutation deleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      device {
        id
      }
    }
  }`,
  errorMessage: "Error deleting device.",
  collection: "deleteDevice.device",
};

export const gqlAddDevice: graphQLQuery = {
  query: `mutation addDevice($input: AddDeviceInput!) {
    addDevice(input: $input) {
      device {
        id
        name
        externalId
        model {
          id
          name
          manufacturer { name }
          deviceType { name }
        }
        site { id name }
        location { id name }
      }
    }
  }`,
  errorMessage: "Error adding device.",
  collection: "addDevice.device",
};
