import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import DataGrid, { Paging, Pager, SearchPanel } from "devextreme-react/data-grid";
import { EventInfo } from "devextreme/events";
import dxDataGrid from "devextreme/ui/data_grid";
import { isNullOrUndefined } from "../../util/nullUtilities";
import { graphQLQuery, query } from "../../Services/GraphQL";
import { IPaginatedConnection } from "./IPaginatedConnection";

interface DataGridWithPagingProps extends React.RefAttributes<DataGridWithPagingRef> {
  /**
   * Method called when a row is selected.
   */
  onSelectionChanged: (e: EventInfo<dxDataGrid>) => void;

  /**
   * Children props passed to the DataGrid, e.g. a bunch of Columns.
   */
  children: React.ReactNode;

  /**
   * The graphQLquery object used to load data.
   */
  loadQuery: graphQLQuery;
}

export interface DataGridWithPagingRef {
  /**
   * Method to refresh data (loads data from the server using graphQL)
   */
  refresh: () => void;

  /**
   * Deselects all/any currently selected rows.
   */
  deselect: () => void;

  /**
   * Selects a specific row by its key (id)
   * @param id The id of the item to be selected
   */
  selectRow: (id: string) => void;
}

/**
 * A wrapper for DataGrid with paging controls.
 * All you need to do is supply a graphQLQuery for loading data which supports pagination.
 */
const DataGridWithPaging: React.FC<DataGridWithPagingProps> = forwardRef<
  DataGridWithPagingRef,
  DataGridWithPagingProps
>(({ onSelectionChanged, loadQuery, children }, ref) => {
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<any[]>([]);
  const gridRef = useRef(null);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      refreshData();
    },
    deselect: () => {
      gridRef.current.instance.deselectAll();
    },
    selectRow: (id: string) => {
      gridRef.current.instance.selectRows([id], false);
    },
  }));

  const refreshData = async () => {
    const afterIndex = pageIndex * pageSize - 1;
    const afterIndexBase64 = btoa(afterIndex.toString());
    const text = searchText;
    const order = sortOrder;
    console.debug(
      "Refreshing data, pageIndex:",
      pageIndex,
      "pageSize:",
      pageSize,
      "after",
      afterIndex,
      "searchText",
      text,
      "order",
      order
    );

    const result = await query<IPaginatedConnection>(loadQuery, {
      first: pageSize,
      after: afterIndexBase64,
      searchText: text,
      sortOrder: order,
    });

    if (isNullOrUndefined(result)) {
      console.error("No paginated items:", result);
      return;
    }

    // We add fake entries to result.nodes so that the data grid component shows the "right" entries that we just fetched.
    // Insert fake entries before the data which is displayed.
    for (let i = 0; i <= afterIndex; i++) {
      result.nodes.unshift(null);
    }

    // Insert fake entries "after" the displayed data so that the total count is still correct.
    const toAdd = result.totalCount - result.nodes.length;
    for (let i = 0; i < toAdd; i++) {
      result.nodes.push(null);
    }
    setItems(result.nodes);
  };

  React.useEffect(() => {
    refreshData();
  }, [pageIndex, pageSize, searchText, sortOrder]);

  const handleOptionChange = (e): void => {
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value);
    } else if (e.fullName === "paging.pageSize") {
      setPageIndex(0); // Also reset page index to prevent funky situations.
      setPageSize(e.value);
    } else if (e.fullName === "searchPanel.text") {
      if (searchText !== e.value) {
        console.debug("Search text:", e.value);
        setPageIndex(0); // Also reset page index to prevent funky situations.
        setSearchText(e.value);
      }
    } else if (e.fullName.includes("sortOrder")) {
      const getColumnSortOrder = e.component
        .getVisibleColumns()
        .filter((c) => c.sortIndex >= 0)
        .sort((a, b) => a.sortIndex - b.sortIndex);
      const sortResult = [];
      getColumnSortOrder.forEach((c, i) => {
        const sortObject = {};
        const fields = c.dataField.split(".");
        let current = sortObject;
        fields.forEach((field, index) => {
          if (index === fields.length - 1) {
            current[field] = c.sortOrder.toUpperCase();
          } else {
            current[field] = {};
            current = current[field];
          }
        });
        sortResult.push(sortObject);
      });
      console.log(sortResult);
      setSortOrder(sortResult);
    }
  };

  return (
    <DataGrid
      onSelectionChanged={onSelectionChanged}
      onOptionChanged={handleOptionChange}
      selection={{ mode: "single" }}
      dataSource={items}
      ref={gridRef}
      style={{ height: "calc(100vh - 220px)" }}
      showBorders={true}
      keyExpr={"id"}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      remoteOperations={{ paging: true, sorting: true }}
      sorting={{ mode: "none" }}
      onEditorPreparing={(e) => {
        if (e.parentType === "searchPanel") {
          e.editorOptions.stylingMode = "outlined";
        }
      }}
    >
      <SearchPanel visible={true} width={240} placeholder="Search..." />
      <Paging pageIndex={pageIndex} pageSize={pageSize} defaultPageSize={10} />
      <Pager
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={[5, 10, 20]}
        showInfo={true}
        showNavigationButtons={true}
      />
      {children}
    </DataGrid>
  );
});

export default DataGridWithPaging;
