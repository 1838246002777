import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import DeviceIcon from "../../assets/icons/ic_devices_24px.svg";
import { Column, Item, Toolbar } from "devextreme-react/data-grid";
import { gqlQueryPaginatedDevices } from "./DeviceQueries";
import DataGridWithPaging, { DataGridWithPagingRef } from "../../Components/DataGridWithPaging/DataGridWithPaging";
import { IDevice } from "./IDevice";

export const DevicePilet: Pilet = {
  name: "Device Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "Devices",
      () => (
        <Link to="/devices">
          <div>
            <div>
              <img src={DeviceIcon}></img>
            </div>
            <div>Devices</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 4, location: "/devices" }
    );

    api.registerTile(
      "Devices",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + DeviceIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/devices">Devices</Link>
              <br />
              Administration of devices.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    api.registerPage("/devices", ({ children }) => {
      const dataGridRef = React.useRef<DataGridWithPagingRef>();
      const [selectedDevice, setSelectedDevice] = React.useState<IDevice | null>();

      const refreshData = () => {
        dataGridRef.current?.refresh();
      };

      React.useEffect(() => {
        api.setData("selectedDevice", null);
        api.setData("addNewDevice", false);

        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "device") {
            refreshData();
          }
        });

        api.on("addNewDevice", (v: boolean) => {
          setSelectedDevice(null);
          dataGridRef.current?.deselect(); // When adding a new device, we should deselect any selected rows to avoid confusion in the UI.
          api.setData("addNewDevice", v);
        });

        api.on("addedNewDevice", (v: IDevice) => {
          setSelectedDevice(v);
          api.setData("selectedDevice", v);
          setTimeout(() => dataGridRef.current?.selectRow(v.id), 300);
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            refreshData();
            api.setData("selectedDevice", null);
            dataGridRef.current?.deselect();
          }
        });
      }, []);

      const onRowSelected = (row) => {
        const selectedDeviceInRow = row.selectedRowsData.length === 0 ? null : (row.selectedRowsData[0] as IDevice);

        setSelectedDevice(selectedDeviceInRow);
        api.setData("selectedDevice", selectedDeviceInRow);
        api.setData("addNewDevice", false);
      };

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ display: "flex", flexGrow: 2, margin: "0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <DataGridWithPaging
                  ref={dataGridRef}
                  loadQuery={gqlQueryPaginatedDevices}
                  onSelectionChanged={onRowSelected}
                >
                  <Toolbar>
                    <Item location="before">
                      <div style={{ fontSize: 24, opacity: 0.8 }}>Devices</div>
                    </Item>
                    <Item name="searchPanel"></Item>
                  </Toolbar>
                  <Column name="Name" dataField="name" dataType="string" />
                  <Column caption="External ID" dataField="externalId" dataType="string" />
                  <Column caption="Model" dataField="model.name" dataType="string" />
                  <Column caption="Device Type" dataField="model.deviceType.name" dataType="string" />
                  <Column caption="Manufacturer" dataField="model.manufacturer.name" dataType="string" />
                  <Column caption="Location" dataField="location.name" dataType="string" />
                </DataGridWithPaging>
              </div>
              <div className="extension-list">
                <api.Extension
                  name="extension-list"
                  params={{ extensions: ["device", `device-${selectedDevice?.model?.deviceType.name}`] }}
                />
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
