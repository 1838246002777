import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { ModalComponentProps } from "piral";
import { Criticality } from "../Devices/Criticality";
import { IDependency } from "../Devices/IDependency";
import { Button } from "devextreme-react";
import { graphQLQuery, mutation, query } from "../../Services/GraphQL";
import { useState } from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { gqlQueryEntityName as gqlQueryEntityNames } from "../Helpers/GenericQueries";

export const confirmDeletionModalName = "confirm-deletion-modal";

function stringToCriticality(value: string): Criticality | undefined {
  switch (value.toLowerCase()) {
    case "prevent":
      return Criticality.Prevent;
    case "warn":
      return Criticality.Warn;
    case "info":
      return Criticality.Info;
    case "noinfo":
      return Criticality.NoInfo;
    case "try_to_delete":
      return Criticality.TryToDelete;
    case "prevent_with_callback":
      return Criticality.PreventWithCallback;
    default:
      console.error("unhandled criticality:", value);
      return undefined;
  }
}

function lowercaseFirstChar(input: string): string {
  if (!input) return input; // Check if the input is an empty string
  return input.charAt(0).toLowerCase() + input.slice(1);
}

function prettifyEntityType(t: string): string {
  if (t === "LibraryItem") return "Library item";
  if (t === "EventTemplate") return "Event template";

  return t;
}

const confirmDeleteComponent = (props: ModalComponentProps<"confirm-deletion-modal">) => {
  const options = props.options as IConfirmDeleteModalOptions;
  const criticalities = options.dependencies.map((x) => stringToCriticality(x.criticality));
  const mostSevereCriticality = getMostSevereCriticality(criticalities);
  const [isLoading, setIsLoading] = useState(true);
  const [dependencies, setDependencies] = useState<string[]>([]);

  if (mostSevereCriticality === Criticality.NoInfo && !options.isGenericDeleteModal) {
    // Nothing to show, close dialog immediately.
    props.onClose();
    options.onAction(false);
    return <></>;
  }

  const modalText = getModalBodyText(mostSevereCriticality, options);

  const dependenciesToShow =
    mostSevereCriticality === Criticality.Prevent
      ? options.dependencies.filter((x) => stringToCriticality(x.criticality) === Criticality.Prevent)
      : options.dependencies.filter((x) => stringToCriticality(x.criticality) !== Criticality.NoInfo);

  const canDelete = mostSevereCriticality !== Criticality.Prevent;
  const deleteButtonText = options.isGenericDeleteModal ? "Delete" : "Delete anyway";

  React.useEffect(() => {
    const asyncWrapper = async () => {
      const dependenciesTemp: string[] = [];

      for (let dep of dependenciesToShow) {
        const colonIndex = dep.system.indexOf(":");
        if (colonIndex !== -1) {
          const parts = dep.system.split(":");
          const entityType = lowercaseFirstChar(parts[0]);
          const entityGuid = parts[1]; // The GUID of the entity on which the item we want to delete depends
          const gqlQuery = gqlQueryEntityNames(entityType);
          const names = await query<IHaveName[]>(gqlQuery, { [entityType + "Ids"]: [entityGuid] });
          dependenciesTemp.push(prettifyEntityType(parts[0]) + ": " + (names[0]?.name || entityGuid));
        } else {
          dependenciesTemp.push(dep.system);
        }
      }
      console.log("Dependencies:", dependenciesTemp);
      setDependencies(dependenciesTemp);
      setIsLoading(false);
    };
    asyncWrapper();
  }, []);

  const closeDialog = () => {
    options.onAction(false);
    props.onClose();
  };

  return (
    <div className="modal-container">
      <div className="modal" style={{ height: "auto", paddingBottom: "18px" }}>
        <div className="modal-header">
          <div className="modal-header-text">Delete {options.type}</div>
          <div className="modal-header-right">
            <a className="modal-button-close" onClick={closeDialog} data-testid="aboutCloseButton"></a>
          </div>
        </div>
        <div className="modal-body" style={{ paddingLeft: 18, paddingRight: 18, maxWidth: "464px" }}>
          {isLoading ? (
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <LoadIndicator />
            </div>
          ) : (
            <>
              {modalText}
              <ul>
                {dependencies.map((d) => (
                  <li key={d}>{d}</li>
                ))}
              </ul>
            </>
          )}

          {canDelete ? (
            <Button
              text="Outlined"
              type="normal"
              stylingMode="outlined"
              onClick={(e) => {
                options.onAction(true);
                props.onClose();
              }}
            >
              {deleteButtonText}
            </Button>
          ) : (
            <Button
              text="Outlined"
              type="normal"
              stylingMode="outlined"
              onClick={(e) => {
                options.onAction(false);
                props.onClose();
              }}
            >
              Close
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * @param dependencies The dependencies of the item we are about to delete.
 * @returns true if there is at least one dependency which is of criticality higher than "NoInfo", meaning we should inform the user.
 */
export function shouldShowDeleteConfirmationModal(dependencies: IDependency[]) {
  const criticalities = dependencies.map((x) => stringToCriticality(x.criticality));
  return criticalities.some(
    (x) => x !== Criticality.NoInfo && x !== Criticality.TryToDelete && x !== Criticality.PreventWithCallback
  );
}

function getMostSevereCriticality(criticalities: Criticality[]) {
  return criticalities.reduce((max, curr) => Math.min(max, curr), Infinity);
}

function getModalBodyText(mostSevereCriticality: Criticality, options: IConfirmDeleteModalOptions) {
  const type = options.type;

  if (options.isGenericDeleteModal) {
    return "Are you sure you want to delete this " + type + "?";
  }

  switch (mostSevereCriticality) {
    case Criticality.Prevent:
      return "The " + type + " cannot be deleted because of the following dependencies:";
    case Criticality.Warn:
    case Criticality.Info:
      return "Are you sure you want to delete this " + type + "? It is used by these dependencies:";
    case Criticality.NoInfo:
    case Criticality.TryToDelete:
    case Criticality.PreventWithCallback:
      return "";
    default:
      console.error("Unhandled criticality:", mostSevereCriticality);
      return null;
  }
}

export interface IConfirmDeleteModalOptions {
  type: string;
  dependencies: IDependency[];

  /**
   * If true, ignore dependencies and use the modal as a generic
   * "Are you sure you want to delete this object" modal.
   */
  isGenericDeleteModal: boolean;

  onAction: (val: any) => void;
}

/**
 * Defines a modal which is shown to inform/warn the user about dependencies when attempting to delete a certain object.
 */
export const ConfirmDeleteModal: Pilet = {
  name: "Confirm Delete Modal Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerModal(confirmDeletionModalName, confirmDeleteComponent);
  },
};

interface IHaveDependencies {
  dependencies: IDependency[];
}

interface IHaveName {
  name: string;
}

/**
 * Generalized method which can be used to load dependencies for an entity before attempting to delete it.
 * Warns the user if the entity cannot be deleted due to dependencies.
 * If there are no dependencies which need to be communicated to the user and deletion can proceed,
 * performs the deletion without any prompts.
 * @returns true if the entity was deleted.
 */
export async function deleteEntityGeneric<T>(
  api: PiletApi,
  entityId: string,
  entityType: string,
  dependenciesQuery: graphQLQuery,
  deleteQuery: graphQLQuery
): Promise<boolean> {
  try {
    if (entityId === "*") return false;
    console.debug("Deleting " + entityType, entityId);

    const dependenciesQueryParams = {};
    dependenciesQueryParams[entityType + "Ids"] = [entityId];
    const deps = await query<IHaveDependencies[]>(dependenciesQuery, dependenciesQueryParams);
    let dependencies = deps[0].dependencies;
    console.debug("Dependencies for entity", dependenciesQueryParams, dependencies);

    const onConfirmDelete = async (confirmed: boolean): Promise<boolean> => {
      if (!confirmed) return false;

      const result = await mutation<T>(deleteQuery, {
        input: { id: entityId },
      });

      if (result) {
        console.log("Deleted " + entityType, entityId);
        api.emit("refresh", { name: entityType, value: entityId, action: "delete" });
        return true;
      } else {
        console.warn("Error deleting " + entityType, entityId);
        return false;
      }
    };

    if (shouldShowDeleteConfirmationModal(dependencies)) {
      const options: IConfirmDeleteModalOptions = {
        type: entityType,
        isGenericDeleteModal: false,
        dependencies: dependencies,
        onAction: async (val) => {
          return await onConfirmDelete(val);
        },
      };

      api.showModal(confirmDeletionModalName, options);
      return new Promise((resolve) => {
        options.onAction = async (val) => {
          resolve(await onConfirmDelete(val));
        };
      });
    } else {
      return await onConfirmDelete(true);
    }
  } catch (error) {
    console.error("Error deleting entity:", entityId, error);
    return false;
  }
}
