import { TestPilets } from "./TestPilets";
import { AboutModal } from "../Components/Modals/AboutModal";
import { MenuPilet } from "./Menus";
import { IdentityPilet } from "./Identities/IdentityPilet";

import { LicensePilet } from "./Licenses/LicensePilet";

import { SitePilet } from "./Sites/SitePilet";
import { SitePropertiesPilet } from "./Sites/SitePropertiesPilet";

import { OrganizationPilet } from "./Organizations/OrganizationPilet";
import { OrganizationPropertiesPilet } from "./Organizations/OrganizationPropertiesPilet";

import { LocationPilet } from "./Locations/LocationPilet";
import { LocationPropertiesPilet } from "./Locations/LocationPropertiesPilet";

import { UserPilet } from "./Users/UserPilet";
import { UserPropertiesPilet } from "./Users/UserPropertiesPilet";

import { DevicePilet } from "./Devices/DevicePilet";
import { DevicePropertiesPilet } from "./Devices/DevicePropertiesPilet";

import { EventTemplatesPilet } from "./EventTemplate/EventTemplatePilet";
import { EventTemplatePropertiesPilet } from "./EventTemplate/EventTemplatePropertiesPilet";

import { LibraryItemPilet } from "./LibraryItems/LibraryItemPilet";
import { LibraryItemPropertiesPilet } from "./LibraryItems/LibraryItemPropertiesPilet";

import { ExtensionsPilet } from "./ExtensionsPilet";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { LogoutConfirmationModel } from "../Components/Modals/LogoutConfirmationModal";
import { GenericOkButtonModal } from "../Components/Modals/GenericOkButtonModal";
import { TenantPropertiesPilet } from "./Tenants/TenantPropertiesPilet";
import { TenantPilet } from "./Tenants/TenantPilet";
import { SettingPilet } from "./Settings/SettingsPilet";

/**
 * Normally all these pilets would come from some API and
 * would look quite different (i.e., not already evaluated etc.).
 *
 * This is only a very simple - for demo-purposes - kind a way.
 * The real development would also have each pilet in its own
 * repository (or at least in its own folder / structure in a
 * monorepo).
 */
export const availablePilets = [
  TestPilets,
  AboutModal,
  LogoutConfirmationModel,
  GenericOkButtonModal,
  ConfirmDeleteModal,
  MenuPilet,
  SitePilet,
  SitePropertiesPilet,
  OrganizationPilet,
  OrganizationPropertiesPilet,
  UserPilet,
  UserPropertiesPilet,
  LocationPilet,
  LocationPropertiesPilet,
  EventTemplatesPilet,
  EventTemplatePropertiesPilet,
  DevicePilet,
  DevicePropertiesPilet,
  LibraryItemPilet,
  LibraryItemPropertiesPilet,
  ExtensionsPilet,
  IdentityPilet,
  LicensePilet,
  TenantPilet,
  TenantPropertiesPilet,
  SettingPilet,
];
