import * as React from "react";
import { createRoot } from "react-dom/client";
import { Redirect } from "react-router-dom";
import { createInstance, Piral, createStandardApi, Dashboard, createModalsApi, PiralInstance } from "piral";
import { layout, errors } from "./layout";
import { isLoggedIn, navigateToLoginPage, initOIDC, UserLoginResult } from "./Services/client";
import { createMyApi } from "./Plugins/plugin";
import { availablePilets } from "./pilets";
import { createBlazorApi } from "piral-blazor";
import { createGqlApi } from "piral-urql";
import { Client, createClient } from "urql";
//import 'devextreme/dist/css/dx.material.blue.light.css';
import "./assets/dx.material.Ascom_Unite_2023.css";
import { getConfig, loadConfig } from "./Services/config";
import { Unauthorized } from "./Components/Unauthorized";
import { getStoredToken, setupRefreshTokenTimer } from "./Services/token";

export let instance: PiralInstance;
export let client: Client;

/**
 * Render the app;
 * If not authorized, show an unauthorized page.
 * If not logged in, redirect to the login page.
 * If logged in, render the app normally.
 */
async function render() {
  const loginResult = await isLoggedIn();

  if (loginResult == UserLoginResult.LoginOkAuthorizationFailed) {
    // error case; user is not authorized
    const container = document.getElementById("app");
    const root = createRoot(container);
    root.render(<Unauthorized />);
    return;
  } else if (loginResult !== UserLoginResult.Ok) {
    // error case; not logged in
    navigateToLoginPage();
    return;
  }

  // success case - user is logged in
  console.log("User is logged in.");
  const config = getConfig();
  const feedUri = config.feedUri;
  let currentSite = "*";
  let currentTenant = null;

  client = createClient({
    url: config.apiBaseUri,
    requestPolicy: "network-only",
    fetchOptions: () => {
      currentSite = instance.root.getData("currentSite");
      currentTenant = instance.root.getData("currentTenant");
      const token = getStoredToken();
      return token
        ? {
            headers: {
              authorization: token ? `Bearer ${token}` : "",
              currentsite: currentSite,
              currenttenant: currentTenant,
            },
          }
        : {};
    },
  });

  instance = createInstance({
    availablePilets,
    state: {
      components: layout,
      errorComponents: errors,
      routes: {
        "/": Dashboard,
        "/auth": () => {
          return <Redirect from="/auth" to="/" />;
        },
      },
    },
    plugins: [...createStandardApi(), createMyApi(), createBlazorApi(), createModalsApi(), createGqlApi(client)],
    requestPilets() {
      return fetch(feedUri)
        .then((res) => res.json())
        .then((res) => res.items);
    },
  });

  setupRefreshTokenTimer();
  instance.root.setData("accessToken", getStoredToken());

  const container = document.getElementById("app");
  const root = createRoot(container);
  root.render(<Piral instance={instance} />);
}

function disablePiralDebugLogging() {
  sessionStorage.setItem("dbg:view-state", "off");
}

/**
 * The entry point for the app
 */
(async () => {
  disablePiralDebugLogging();
  await loadConfig();
  await initOIDC();
  render();
})();
