/**
 * Returns true if the value is null or undefined.
 */
export function isNullOrUndefined(value: any): boolean {
  return value === undefined || value === null;
}

/**
 * @param input The string to check.
 * @returns True if the string is null, undefined, empty or consists only of whitespace.
 */
export function isNullOrWhitespace(input: string | null | undefined): boolean {
  return !input || input.trim().length === 0;
}
