import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { gqlAddSite, gqlDeleteSite, gqlSite, gqlUpdateSite } from "./SiteQuery";
import { mutation, query } from "../../Services/GraphQL";
import TextBox from "devextreme-react/text-box";
import { Validator, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import Button from "devextreme-react/button";
import {
  IConfirmDeleteModalOptions,
  confirmDeletionModalName,
  shouldShowDeleteConfirmationModal,
} from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ISite } from "./ISite";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";
import { getApiRealBaseUrl } from "../../Services/config";
import { IDependency } from "../Devices/IDependency";

export const SitePropertiesPilet: Pilet = {
  name: "Site Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "site-properties",
      ({ params }) => {
        const emptySite: ISite = {
          id: "",
          name: "",
          dependencies: [],
        };
        const [site, setSite] = useState<ISite>(emptySite);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedSite") {
              await refreshData(value);
            }
          });
        }, []);

        const refreshData = async (siteId) => {
          setEditMode(false);
          setDeleteInProgress(false);

          if (siteId === "") {
            setSite(emptySite);
            return;
          }

          const result = await query(gqlSite, { siteId: [siteId] });
          if (result) setSite(result[0]);
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const siteToSave = {
              name: site.name,
            };

            if (site.id === "*") {
              const result = await mutation<ISite>(gqlAddSite, { input: siteToSave });

              if (result) {
                setSite(result);
                api.emit("refresh", { name: "site", value: result.id });
                setEditMode(false);
              } else {
                api.showNotification("Error adding site", { type: "error" });
              }

              return;
            }

            const result = await mutation<ISite>(gqlUpdateSite, { id: site.id, input: siteToSave });
            if (result) {
              setSite(result);
              api.emit("refresh", { name: "site", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating site", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newSite = { ...site };
            newSite[field] = e.value;
            setSite(newSite);
          }
        };

        const deleteSite = async () => {
          try {
            if (site.id === "*") return;

            setDeleteInProgress(true);
            const siteDependenciesRequest = await fetch(getApiRealBaseUrl() + "/api/sites/dependencies/" + site.id, {
              headers: {
                currenttenant: api.getData("currentTenant"),
                Authorization: "Bearer " + api.getData("accessToken"),
              },
            });
            const dependencies: string[] = await siteDependenciesRequest.json();
            const dependenciesMapped: IDependency[] = dependencies.map((x) => {
              return { system: x, criticality: "prevent" };
            });

            const onConfirmDelete = async (confirmed: boolean) => {
              if (!confirmed) {
                setDeleteInProgress(false);
                return;
              }

              console.debug("Deleting site", site.id);

              const result = await mutation<ISite>(gqlDeleteSite, {
                input: { id: site.id },
              });
              if (result) {
                console.log("Deleted site", site.id);
                setSite(emptySite);
                api.emit("refresh", { name: "site", value: result.id });
              } else {
                api.showNotification("Error deleting site", {
                  type: "error",
                });
              }
              setDeleteInProgress(false);
            };

            const options: IConfirmDeleteModalOptions = {
              type: "site",
              dependencies: dependenciesMapped,
              isGenericDeleteModal: dependenciesMapped.length === 0,
              onAction: (val) => {
                onConfirmDelete(val);
              },
            };

            api.showModal(confirmDeletionModalName, options);
          } catch (error) {
            console.error("Error deleting site:", error);
          }
        };

        const onAddClick = () => {
          setEditMode(true);
          setSite({ ...emptySite, id: "*" });
        };

        if (site.id === "")
          return (
            <>
              <div style={{ flexGrow: 1 }}></div>
              <div style={{ padding: "0.75rem 1rem" }}>
                <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                  <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>Add New
                </Button>
              </div>
            </>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRow
                  editMode={editMode}
                  onCancel={() => {
                    if (site.id == "*") {
                      setSite({ ...emptySite });
                    } else {
                      refreshData(site.id);
                    }

                    setEditMode(false);
                  }}
                  deleteInProgress={deleteInProgress}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteSite()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChanged={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={site.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "site", sortOrder: 1, name: "Site properties", icon: InfoIcon }
    );
  },
};
