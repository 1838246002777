import { generateCodeVerifier, generateCodeChallengeFromVerifier } from "./crypto";
import { addQueryParametersToUrl, buildQueryString } from "../util/urlUtilities";
import { getConfig } from "./config";
import { jwtDecode } from "jwt-decode";
import { getTokenAndRefreshIfExpired, resetTokenData, setTokenData } from "./token";
import { isNullOrWhitespace } from "../util/nullUtilities";

let identityProviderUri = "";
let authUri = `${identityProviderUri}/auth`;
let userUri = `${identityProviderUri}/userinfo`;
let logoutUri = `${identityProviderUri}/logout`;
let logoutRedirectUrl = `${location.origin}/`;
let scope = "openid";
let requiredRealmRole: string | undefined = undefined;

export let clientId = "aup";
export let redirectUri = `${location.origin}/auth`;
export let tokenUri = `${identityProviderUri}/token`;
export let client_secret = "BAjYhzVLPHyHIqvaah8x5IlU2JrGZsy9";

export interface User {
  sub: string;
  preferred_username: string;
  DOB: string;
  organization: string;
}

export enum UserLoginResult {
  LoginFailed,
  LoginOkAuthorizationFailed,
  Ok,
}

export function setupClient() {
  const config = getConfig();
  console.log("Setting up client");
  identityProviderUri = config.identityProviderUri;
  authUri = `${identityProviderUri}/auth`;
  userUri = `${identityProviderUri}/userinfo`;
  tokenUri = `${identityProviderUri}/token`;
  logoutUri = `${identityProviderUri}/logout`;
  clientId = config.clientId;
  redirectUri = `${location.origin}/auth`;
  logoutRedirectUrl = `${location.origin}/`;
  client_secret = config.client_secret;
  scope = "openid";
  requiredRealmRole = config.requiredRealmRole;
}

export function getUserInfo(): Promise<User> {
  return getTokenAndRefreshIfExpired()
    .then((token) =>
      fetch(userUri, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
    )
    .then((res) => res.json());
}

export async function navigateToLoginPage() {
  const verifier = generateCodeVerifier();
  sessionStorage.setItem("verifier", verifier);
  const challenge = await generateCodeChallengeFromVerifier(verifier);
  const query = buildQueryString({
    client_id: clientId,
    redirect_uri: redirectUri,
    client_secret: client_secret, // not needed
    scope: scope,
    response_type: "code",
    code_challenge: challenge,
    code_challenge_method: "S256",
  });
  location.href = `${authUri}?${query}`;
}

function getLoginResultFromAccessToken(accessToken: string): UserLoginResult {
  if (!accessToken) {
    return UserLoginResult.LoginFailed;
  }

  if (!requiredRealmRole) {
    console.debug("No configured required realm role.");
    return UserLoginResult.Ok;
  }

  let isAuthenticated = false;

  try {
    const decodedToken = jwtDecode(accessToken) as any;
    isAuthenticated = decodedToken.realm_access.roles.some((roleName) => roleName == requiredRealmRole);
  } catch (error) {
    console.debug(`Error reading realm role from token: ${error?.message}`);
    return UserLoginResult.LoginOkAuthorizationFailed;
  }

  return isAuthenticated ? UserLoginResult.Ok : UserLoginResult.LoginOkAuthorizationFailed;
}

export function getTenantAdminRoleFromAccessToken(accessToken: string): ConstrainBoolean {
  if (!accessToken) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(accessToken) as any;
    return decodedToken.realm_access.roles.some((roleName) => roleName == "TenantAdministrator");
  } catch (error) {
    console.debug(`Error reading realm role from token: ${error?.message}`);
    return false;
  }
}

export async function isLoggedIn(): Promise<UserLoginResult> {
  const token = await getTokenAndRefreshIfExpired();

  if (!isNullOrWhitespace(token)) {
    return getLoginResultFromAccessToken(token);
  }

  return UserLoginResult.LoginFailed;
}

export function logout() {
  const queryParams = {
    id_token_hint: sessionStorage.getItem("id_token"),
    post_logout_redirect_uri: logoutRedirectUrl,
  };

  resetTokenData();
  location.href = addQueryParametersToUrl(logoutUri, queryParams);
}

export async function initOIDC() {
  console.log("OIDC Async start");
  setupClient();

  if (location.pathname !== "/auth") return;

  console.log("OIDC path is auth");
  const verifier = sessionStorage.getItem("verifier");
  const queryParams = Object.fromEntries(
    location.search
      .substring(1)
      .split("&")
      .map((s) => s.split("="))
  );

  if (verifier && queryParams.code) {
    const data = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: buildQueryString({
        grant_type: "authorization_code",
        client_id: clientId,
        scope: scope,
        client_secret: client_secret,
        code: queryParams.code,
        redirect_uri: redirectUri,
        code_verifier: verifier,
      }),
    };

    try {
      const res = await fetch(tokenUri, data);
      console.log(`OIDC Fetch token: ${res.status}`);

      if (res.status === 200) {
        const data = await res.json();
        setTokenData(data);
      } else {
        throw await res.json();
      }
    } catch (err) {
      console.error("OIDC Error during token retrieval", err);
      resetTokenData();
    }
  }
}
