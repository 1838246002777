import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import Button from "devextreme-react/button";
import TextBox from "devextreme-react/text-box";
import {
  gqlAddLocation,
  gqlDeleteLocation,
  gqlLocation,
  gqlLocationTypes,
  gqlOrganizations,
  gqlQueryLocationDependencies,
  gqlUpdateLocation,
} from "./LocationQuery";
import Validator, { RequiredRule, StringLengthRule } from "devextreme-react/validator";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ILocation } from "../Locations/ILocation";
import TreeView from "devextreme-react/tree-view";
import { IOrganization, ISelectableOrganization } from "../Organizations/IOrganization";
import EditButtonRowWithParentChild from "../../Components/EditButtonRow/EditButtonRowWithParentChild";
import { SelectBox } from "devextreme-react";

export const LocationPropertiesPilet: Pilet = {
  name: "Location Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "location-properties",
      ({ params }) => {
        const emptyLocation: ILocation = {
          id: "",
          parentId: null,
          name: "",
          dialNumber: "",
          type: "",
          site: { id: "" },
          organizations: [],
          dependencies: [],
        };

        const [location, setLocation] = useState<ILocation>(emptyLocation);
        const [site, setSite] = useState<string>("");
        const [editMode, setEditMode] = useState<boolean>(false);
        const [organizations, setOrganizations] = useState<ISelectableOrganization[]>([]);
        const [types, setTypes] = useState<string[]>([]);
        const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        const areaWrapperPaddingDynamicHeightStyle: React.CSSProperties = {
          border: "1px solid rgb(0,0,0,0.52)",
          padding: "6px 10px 0 15px",
          borderRadius: "4px",
          marginBottom: "15px",
          marginTop: "5px",
        };

        const labelStyle: React.CSSProperties = {
          fontSize: "12px",
          position: "relative",
          top: "-16px",
          zIndex: "2",
          backgroundColor: "white",
          paddingLeft: "2px",
          paddingRight: "2px",
          color: "rgba(148,148,148,.87)",
        };

        React.useEffect(() => {
          setSite(api.getData("currentSite"));
          loadOrganizations();

          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedLocation" && value != "") {
              await refreshTypes();
              await refreshData(value);
            }

            if (name === "currentSite") {
              setSite(value);
            }

            if (name === "currentTenant") {
              loadOrganizations();
              refreshTypes();
            }
          });
        }, []);

        const loadOrganizations = async () => {
          const result = await query<ISelectableOrganization[]>(gqlOrganizations);

          setOrganizations(result);
        };

        const refreshTypes = async () => {
          const result = await query<any>(gqlLocationTypes);
          setTypes(result.map((s) => s.type));
        };

        const refreshOrganizations = async (organizations) => {
          setOrganizations((orgs) => {
            const updatedOrganizations = orgs.map((org) => {
              if (organizations.some((l) => l == org.id)) {
                return { ...org, selected: true };
              }

              if (org.selected) {
                return { ...org, selected: false };
              }

              return org;
            });

            return updatedOrganizations;
          });
        };

        const refreshData = async (locationId) => {
          setEditMode(false);
          setDeleteInProgress(false);

          const result = await query(gqlLocation, { locationId: [locationId] });
          if (result) {
            setLocation(result[0]);
            refreshOrganizations(result[0].organizations.map((l) => l.id));
          }
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const locationToSave = {
              parentId: location.parentId,
              name: location.name,
              type: location.type,
              dialNumber: location.dialNumber,
              siteId: null,
              organizations: location.organizations.map((l) => l.id as string),
            };

            if (site === "*") {
              locationToSave.siteId = location.site.id;
            }

            if (location.id === "*") {
              const result = await mutation<ILocation>(gqlAddLocation, { input: locationToSave });

              if (result) {
                setLocation(result);
                api.emit("refresh", { name: "location", value: result.id, action: "add" });
                setEditMode(false);
              } else {
                api.showNotification("Error adding location", { type: "error" });
              }

              return;
            }

            delete locationToSave.siteId;
            const result = await mutation<ILocation>(gqlUpdateLocation, { id: location.id, input: locationToSave });
            if (result) {
              setLocation(result);
              api.emit("refresh", { name: "location", value: result.id, action: "update" });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating location", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newLocation = { ...location };
            newLocation[field] = e.value;
            setLocation(newLocation);
          }
        };

        const handleValueChangedType = async (e, field) => {
          var newLocation = { ...location };
          newLocation[field] = e;
          setLocation(newLocation);
        };

        const deleteLocation = async () => {
          setDeleteInProgress(true);
          const result = await deleteEntityGeneric<ILocation>(
            api,
            location.id,
            "location",
            gqlQueryLocationDependencies,
            gqlDeleteLocation
          );

          if (result === true) {
            setLocation(emptyLocation);
          }
          setDeleteInProgress(false);
        };

        const onNewType = async (e: any) => {
          e.customItem = e.text;
          setTypes((t) => [...t, e.customItem]);
        };

        const onAddClick = async (e) => {
          const parent = e.itemData == "Add root" ? null : location.id;

          setEditMode(true);
          setLocation({ ...emptyLocation, id: "*", parentId: parent, site: { id: location.site.id } });
          refreshOrganizations([]);
        };

        if (location.id === "")
          return (
            <div>
              <div style={{ display: "flex" }}>
                {site !== "*" ? (
                  <>
                    <div style={{ flexGrow: 1 }}></div>
                    <div style={{ padding: "0.75rem 1rem" }}>
                      <Button
                        width={120}
                        text="Outlined"
                        type="normal"
                        stylingMode="outlined"
                        onClick={() => {
                          setEditMode(true);
                          setLocation({ ...emptyLocation, id: "*" });
                        }}
                      >
                        <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>Add New
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>To add a location select a site.</div>
                )}
              </div>
            </div>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRowWithParentChild
                  editMode={editMode}
                  deleteInProgress={deleteInProgress}
                  onCancel={() => {
                    if (location.id == "*") {
                      setLocation({ ...emptyLocation });
                    } else {
                      refreshData(location.id);
                    }

                    setEditMode(false);
                  }}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteLocation()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChanged={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={location.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={40} message="Max 40 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      readOnly={!editMode}
                      label="Type"
                      items={types}
                      onValueChange={(e) => handleValueChangedType(e, "type")}
                      labelMode="floating"
                      stylingMode="outlined"
                      acceptCustomValue={true}
                      onCustomItemCreating={onNewType}
                      value={location.type}
                    ></SelectBox>
                  </div>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Dial number"
                    onValueChanged={(e) => handleValueChanged(e, "dialNumber")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={location.dialNumber}
                  >
                    <Validator>
                      <StringLengthRule max={15} message="Max 15 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={areaWrapperPaddingDynamicHeightStyle}>
                  <span style={labelStyle}>Organizations</span>
                  <div style={formPaddingStyle}>
                    <TreeView
                      disabled={!editMode}
                      items={organizations.filter((o) => o.sites.some((s) => s.id == location.site.id))}
                      dataStructure="plain"
                      selectedItemKeys={location.organizations.map((l) => l.id)}
                      keyExpr="id"
                      selectedExpr="selected"
                      parentIdExpr="parentId"
                      selectionMode="multiple"
                      displayExpr="name"
                      searchEnabled={true}
                      searchMode="contains"
                      selectNodesRecursive={false}
                      showCheckBoxesMode="normal"
                      onItemSelectionChanged={(e) => {
                        setLocation({
                          ...location,
                          organizations: e.component.getSelectedNodeKeys().map((key) => {
                            return { id: key, name: "" } as IOrganization;
                          }),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "location", sortOrder: 1, name: "Location properties", icon: InfoIcon }
    );
  },
};
