import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import { useState } from "react";
import TenantIcon from "../../assets/icons/ic_locations_buildings_24px.svg";
import { gqlTenants } from "./TenantQuery";
import { query } from "../../Services/GraphQL";
import DataGrid, { Column, Item, Toolbar } from "devextreme-react/data-grid";
import Tabs from "devextreme-react/tabs";
import { ITenant } from "./ITenant";

export const TenantPilet: Pilet = {
  name: "Tenant Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerPage("/tenants", ({ children }) => {
      const [tenants, setTenants] = useState<ITenant[]>([]);

      const refreshData = async () => {
        const result = await query<ITenant[]>(gqlTenants);
        setTenants(result);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "tenant") {
            refreshData();
            api.setData("selectedTenant", "");
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentTenant") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
          }
        });
      }, []);

      const onRowSelected = (row) => {
        api.setData("selectedTenant", row.selectedRowsData.length === 0 ? "" : row.currentSelectedRowKeys[0]);
      };

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <DataGrid
                  onSelectionChanged={onRowSelected}
                  selection={{ mode: "single" }}
                  dataSource={tenants}
                  keyExpr="id"
                  showBorders={true}
                  searchPanel={{ visible: true, width: 240, placeholder: "Search..." }}
                  onEditorPreparing={(e) => {
                    if (e.parentType === "searchPanel") {
                      e.editorOptions.stylingMode = "outlined";
                    }
                  }}
                >
                  <Toolbar>
                    <Item location="before">
                      <div style={{ fontSize: 24, opacity: 0.8 }}>Tenants</div>
                    </Item>
                    <Item name="searchPanel"></Item>
                  </Toolbar>
                  <Column name="Name" dataField="name" dataType="string" />
                </DataGrid>
              </div>
              <div className="extension-list">
                <api.Extension name="extension-list" params={{ extensions: "tenant" }}></api.Extension>
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
