import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Button } from "devextreme-react";
import { ModalComponentProps } from "piral";

export const GenericOkButtonPiletName = "ok-button-modal";

export interface IGenericOkButtonModalOptions {
  title: string;
  body: string;
}

const OkButtonModalComponent = (props: ModalComponentProps<"ok-button-modal">) => {
  const options = props.options as IGenericOkButtonModalOptions;

  const bodyWithNewlines = options.body.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

  return (
    <div className="modal-container">
      <div className="modal" style={{ height: "auto" }}>
        <div className="modal-header">
          <div className="modal-header-text">{options.title}</div>
          <div className="modal-header-right">
            <a className="modal-button-close" onClick={() => props.onClose()} data-testid="closeButton"></a>
          </div>
        </div>
        <div className="modal-body" style={{ paddingLeft: 18, width: "90%" }}>
          {bodyWithNewlines}
          <div style={{ marginBottom: 16, marginTop: 16, gap: 8, display: "flex" }}>
            <Button
              text="Outlined"
              type="normal"
              stylingMode="outlined"
              onClick={() => {
                props.onClose();
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Modal for showing a generic modal with a title and a body and an OK button to close the dialog.
 */
export const GenericOkButtonModal: Pilet = {
  name: "Generic Ok Button Modal Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerModal(GenericOkButtonPiletName, OkButtonModalComponent);
  },
};
