import { graphQLQuery } from "../../Services/GraphQL";

export const gqlTenants: graphQLQuery = {
  query: `query {
    tenants {
      id
      name
    }
  }`,
  errorMessage: "Error loading tenants.",
  collection: "tenants",
};

export const gqlTenant: graphQLQuery = {
  query: `query tenants($tenantId: [UUID!]) {
    tenants(ids: $tenantId) {
      id
      name
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "tenants",
};

export const gqlUpdateTenant: graphQLQuery = {
  query: `mutation updateTenant($id: UUID!, $input: UpdateTenantInput!) {
    updateTenant(id: $id, input: $input) {
      tenant {
        id
        name
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "updateTenant.tenant",
};

export const gqlAddTenant: graphQLQuery = {
  query: `mutation addTenant($input: AddTenantInput!) {
    addTenant(input: $input) {
      tenant {
        id
        name
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "addTenant.tenant",
};

export const gqlDeleteTenant: graphQLQuery = {
  query: `mutation deleteTenant($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      tenant {
        id
      }
    }
  }`,
  errorMessage: "Error deleting tenant.",
  collection: "deleteTenant.tenant",
};

export const gqlTenantSettings: graphQLQuery = {
  query: `query tenants($tenantId: [UUID!]) {
    tenants(ids: $tenantId) {
      id
      settings {
        settingKey
        value
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "tenants",
};

export const gqlTenantSettingsConcurrencyCheck: graphQLQuery = {
  query: `query tenants($setting: SettingFilter) {
    tenants(settings: $setting) {
      id
      settings {
        settingKey
        value
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "tenants",
};

export const gqlAddTenantSetting: graphQLQuery = {
  query: `mutation addTenantSetting($input: AddSettingInput!) {
    addTenantSetting(input: $input) {
      tenant {
        id
        settings {
          settingKey
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "addTenantSetting.tenant",
};

export const gqlRemoveTenantSetting: graphQLQuery = {
  query: `mutation removeTenantSetting($input: RemoveSettingInput!) {
    removeTenantSetting(input: $input) {
      tenant {
        id
        settings {
          settingKey
          value
        }
      }
    }
  }`,
  errorMessage: "Error loading tenant.",
  collection: "removeTenantSetting.tenant",
};

export const gqlTenantSystems: graphQLQuery = {
  query: `query {
    tenantSettingSystems { settingKey }
  }`,
  errorMessage: "Error loading tenant systems.",
  collection: "tenantSettingSystems",
};
