import { Button, DropDownButton, LoadIndicator } from "devextreme-react";
import * as React from "react";
import EditIcon from "../../assets/icons/ic_edit_24px.svg";

const EditButtonRowWithParentChild = ({ editMode, onCancel, onEdit, onDelete, onSave, onAdd, deleteInProgress }) => {
  const btnWidthPx = 116;

  return (
    <div style={{ padding: "0.75rem 1rem", display: "flex" }}>
      <Button
        visible={editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onCancel}
      >
        <span className="dx-icon-close" style={{ fontSize: "18px" }}></span>
        Cancel
      </Button>
      <DropDownButton
        text="Add"
        icon="add"
        width={btnWidthPx}
        visible={!editMode}
        items={["Add root", "Add child"]}
        onItemClick={onAdd}
      ></DropDownButton>
      &nbsp;
      <Button
        visible={!editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onEdit}
      >
        <img src={EditIcon} style={{ width: "18px" }} />
        Edit
      </Button>
      &nbsp;
      <Button
        visible={!editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        disabled={deleteInProgress}
        onClick={onDelete}
      >
        <div style={{ marginRight: 10, display: deleteInProgress ? "block" : "none" }}>
          <LoadIndicator height={16} width={16} />
        </div>
        <span className="dx-icon-trash" style={{ fontSize: "18px" }}></span>
        Delete
      </Button>
      &nbsp;
      <Button
        visible={editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onSave}
      >
        <span className="dx-icon-floppy" style={{ fontSize: "18px" }}></span>
        Save
      </Button>
    </div>
  );
};

export default EditButtonRowWithParentChild;
