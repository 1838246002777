import { client_secret, clientId, redirectUri, tokenUri } from "./client";
import { buildQueryString } from "../util/urlUtilities";
import { isNullOrUndefined } from "../util/nullUtilities";
import { instance } from "..";

let refreshTokenTimer = null;

interface TokenData {
  id_token: string;
  id_token_expires_in: number;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
}

async function updateToken() {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: buildQueryString({
      grant_type: "refresh_token",
      client_id: clientId,
      refresh_token: sessionStorage.getItem("refresh_token"),
      client_secret: client_secret,
    }),
  };

  console.debug("Refreshing token now.");

  try {
    const res = await fetch(tokenUri, data);

    if (res.status === 200) {
      const tokenData = (await res.json()) as TokenData;
      setTokenData(tokenData);
      instance.root.setData("accessToken", tokenData.access_token);

      return tokenData;
    } else {
      const errorData = await res.json();
      throw errorData;
    }
  } catch (err) {
    console.error("Error during token update", err);
    resetTokenData();
    throw err;
  }
}

/**
 * @param seconds Number of seconds to add to Date.now()
 * @returns The current timestamp (Date.now()) plus the given number of seconds, as a string.
 */
function getExpirationTimestamp(seconds: number): string {
  return `${seconds * 1000 + Date.now()}`;
}

export function setTokenData(c: TokenData) {
  if (
    typeof c.access_token !== "string" ||
    typeof c.refresh_token !== "string" ||
    typeof c.expires_in !== "number" ||
    typeof c.refresh_expires_in !== "number"
  ) {
    throw new Error("Invalid token data received.");
  }

  console.debug("Token updated. Expires in:", c.expires_in);
  sessionStorage.setItem("access_token", c.access_token);
  sessionStorage.setItem("id_token", c.id_token);
  sessionStorage.setItem("refresh_token", c.refresh_token);
  sessionStorage.setItem("expires_in", getExpirationTimestamp(c.expires_in));
  sessionStorage.setItem("refresh_expires_in", getExpirationTimestamp(c.refresh_expires_in));
}

/**
 * @returns The amount of milliseconds the token is still valid for.
 */
export function msUntilTokenExpires(): number {
  const token = getStoredToken();

  if (!isNullOrUndefined(token)) {
    const expiresAt = +sessionStorage.getItem("expires_in");
    return expiresAt - Date.now();
  }

  return 0;
}

function msUntilRefreshTokenExpires(): number {
  if (!isNullOrUndefined(getStoredToken())) {
    const expiresAt = +sessionStorage.getItem("refresh_expires_in");
    const msUntilRefreshTokenExpires = expiresAt - Date.now();
    console.debug("Refresh token expires in", msUntilRefreshTokenExpires / 1000, "s");
    return msUntilRefreshTokenExpires;
  }

  return 0;
}

let refreshTokenFailures = 0;
export async function refreshToken() {
  if (msUntilRefreshTokenExpires() <= 1000) {
    console.warn("Refresh token is expiring; logging out.");
    resetTokenData();
    location.href = redirectUri;
    return;
  }

  clearInterval(refreshTokenTimer);

  try {
    await updateToken();
    const msUntilNextRefresh = getMsUntilNextRefreshToken();
    refreshTokenFailures = 0;
    refreshTokenTimer = setInterval(refreshToken, msUntilNextRefresh);
    console.log("Token refreshed. Next refresh happens in", msUntilNextRefresh / 1000, "s");
  } catch (e) {
    refreshTokenFailures++;
    console.warn("refreshToken failed calls: " + refreshTokenFailures, e);
    refreshTokenTimer = setInterval(refreshToken, 10000);
  }
}

export function setupRefreshTokenTimer() {
  var msBeforeRefresh = getMsUntilNextRefreshToken();
  console.log("Refreshing token in", msBeforeRefresh / 1000, "s", "expires in s:", msUntilTokenExpires() / 1000);
  refreshTokenTimer = setInterval(refreshToken, msBeforeRefresh);
}

/**
 * Returns the amount of ms we should wait before we refresh the token.
 * This will be the amount of time the current token is valid for, minus a buffer.
 * @returns The amount of milliseconds we should wait before we refresh the token.
 */
function getMsUntilNextRefreshToken() {
  var tokenExpirationMs = msUntilTokenExpires();

  // If token expires in more than 10 minutes, refresh in 5 minutes before it expires.
  // else if token expires in less than 10 minutes, refresh token 5 seconds before it expires.
  const msUntilTokenExpiresMinusBuffer =
    tokenExpirationMs > 10 * 60000 ? tokenExpirationMs - 5 * 60000 : tokenExpirationMs - 5000;

  var minSafeTimeToRefresh = Math.max(0, msUntilTokenExpiresMinusBuffer);
  const thirtyMinutesAsMs = 30 * 60 * 1000;

  // Do not wait more than 30 minutes before refreshing the token.
  return Math.min(minSafeTimeToRefresh, thirtyMinutesAsMs);
}

export function resetTokenData() {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("expires_in");
  sessionStorage.removeItem("refresh_expires_in");
  sessionStorage.removeItem("verifier");
}

/**
 * Gets the access token if we have it.
 * If the token is expired, refreshes the token and returns the newly refreshed access token.
 * @returns The access token.
 */
export async function getTokenAndRefreshIfExpired(): Promise<string> {
  const token = getStoredToken();

  if (!isNullOrUndefined(token)) {
    const expires = +sessionStorage.getItem("expires_in");

    if (Date.now() > expires) {
      console.warn("Token is expired; updating token now");
      const t = await updateToken();
      return t.access_token;
    }
  }

  return token;
}

export function getStoredToken(): string | null {
  return sessionStorage.getItem("access_token");
}
