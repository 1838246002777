import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import { useState } from "react";
import EventTemplateIcon from "../../assets/icons/ic_alert_template_24px.svg";
import { gqlQueryEventTemplates } from "./EventTemplateQuery";
import { query } from "../../Services/GraphQL";
import DataGrid, { Column, Item, Toolbar } from "devextreme-react/data-grid";
import Tabs from "devextreme-react/tabs";
import SoundIcon from "../../assets/icons/sound.jpg";
import { IEventTemplate } from "./IEventTemplate";
import { priorities, colors, colorTranslation } from "./Constants";

export const EventTemplatesPilet: Pilet = {
  name: "EventTemplates Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "EventTemplates",
      () => (
        <Link to="/eventtemplates">
          <div>
            <div>
              <img src={EventTemplateIcon}></img>
            </div>
            <div>Event Templates</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 5, location: "/eventtemplates" }
    );

    api.registerTile(
      "EventTemplates",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + EventTemplateIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/eventtemplates">Event Templates</Link>
              <br />
              Administration of event templates.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    api.registerPage("/eventtemplates", ({ children }) => {
      const [eventTemplates, setEventTemplates] = useState<IEventTemplate[]>([]);

      const refreshData = async () => {
        const result = await query<IEventTemplate[]>(gqlQueryEventTemplates);
        setEventTemplates(result);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "eventTemplate") {
            refreshData();
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
          }
        });
      }, []);

      const imageUrl = (e) => {
        if (e != undefined) return e + "&width=25";

        return e;
      };

      const renderColor = (e) => {
        return (
          <>
            <span
              style={{
                backgroundColor: colorTranslation(e.data.color),
                width: "25px",
                display: "inline-block",
                borderRadius: "4px",
              }}
            >
              &nbsp;
            </span>
            <span style={{ paddingLeft: "5px" }}>{colors.find((c) => c.id == e.data.color)?.name}</span>
          </>
        );
      };

      const renderIcon = (e) => {
        return (
          <>
            <span style={{ display: "inline-block" }}>
              <img style={{ width: "25px", height: "25px" }} src={imageUrl(e.data.icon?.url)} />
            </span>
            <span style={{ display: "inline-block", verticalAlign: "top", marginTop: "5px", paddingLeft: "5px" }}>
              {e.data.icon?.category}:{e.data.icon?.item}
            </span>
          </>
        );
      };

      const renderSound = (e) => {
        return (
          <>
            <span style={{ display: "inline-block" }}>
              <img style={{ width: "25px", height: "25px" }} src={SoundIcon} />
            </span>
            <span style={{ display: "inline-block", verticalAlign: "top", marginTop: "5px", paddingLeft: "5px" }}>
              {e.data.sound?.category}:{e.data.sound?.item}
            </span>
          </>
        );
      };

      const onRowSelected = (row) => {
        api.setData("selectedEventTemplate", row.selectedRowsData.length === 0 ? "" : row.currentSelectedRowKeys[0]);
      };

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <DataGrid
                  onSelectionChanged={onRowSelected}
                  selection={{ mode: "single" }}
                  dataSource={eventTemplates}
                  keyExpr="id"
                  showBorders={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  searchPanel={{ visible: true, width: 240, placeholder: "Search..." }}
                  onEditorPreparing={(e) => {
                    if (e.parentType === "searchPanel") {
                      e.editorOptions.stylingMode = "outlined";
                    }
                  }}
                >
                  <Toolbar>
                    <Item location="before">
                      <div style={{ fontSize: 24, opacity: 0.8 }}>Event Templates</div>
                    </Item>
                    <Item name="searchPanel"></Item>
                  </Toolbar>
                  <Column name="Name" dataField="name" dataType="string" />
                  <Column
                    caption="Priority"
                    dataField="priority"
                    dataType="string"
                    calculateCellValue={(rowData) => priorities.find((p) => p.id == rowData.priority)?.name}
                  />
                  <Column caption="Color" cellRender={renderColor} />
                  <Column caption="Icon" cellRender={renderIcon} />
                  <Column caption="Sound" cellRender={renderSound} />
                </DataGrid>
              </div>
              <div className="extension-list">
                <api.Extension name="extension-list" params={{ extensions: "eventtemplate" }}></api.Extension>
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
