import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import {
  gqlAddEventTemplate,
  gqlDeleteEventTemplate,
  gqlLocations,
  gqlQueryEventTemplate,
  gqlQueryEventTemplateDependencies,
  gqlUpdateEventTemplate,
} from "./EventTemplateQuery";
import { Button } from "devextreme-react/button";
import TextBox from "devextreme-react/text-box";
import Validator, { CustomRule, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import SelectBox from "devextreme-react/select-box";
import { Color, ColorField } from "./Color";
import { gqlLibraryItems } from "../LibraryItems/LibraryItemQuery";
import { Icon, IconField } from "./Icon";
import DataSource from "devextreme/data/data_source";
import Switch from "devextreme-react/switch";
import SoundIcon from "../../assets/icons/sound.jpg";
import validationEngine from "devextreme/ui/validation_engine";
import TreeView from "devextreme-react/tree-view";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import {
  IAddParametersModalOptions,
  IAddPriorityOverrideModalOptions,
  IEventTemplate,
  IParameter,
  IPriorityOverrideInput,
} from "./IEventTemplate";
import { ILocation } from "../Locations/ILocation";
import { ILocationTreeNode, ISelectableLocation, updateNodeRecursively } from "../Devices/ILocation";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";
import { ItemSelectionChangedEvent } from "devextreme/ui/tree_view";
import { ItemClickEvent } from "devextreme/ui/tree_view";
import { priorities, colors, colorTranslation } from "./Constants";
import { ISite } from "../Sites/ISite";
import { buildTree } from "../../util/treeUtilities";
import { ILibraryItem } from "../LibraryItems/ILibraryItem";

export const EventTemplatePropertiesPilet: Pilet = {
  name: "Event Template Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    const eventTemplateAddParametersModal = "event-template-add-parameters";
    const eventTemplateAddPriorityOverrideModal = "event-template-add-priority-override";

    api.registerExtension(
      "event-template-properties",
      ({ params }) => {
        const emptyEventTemplate: IEventTemplate = {
          id: "",
          name: "",
          dependencies: [],
          site: { id: "", name: "", dependencies: [] },
          priority: "INFO",
          color: "RED",
          icon: {
            id: "",
            url: "",
            item: "",
            category: "",
            contentType: "",
          },
          sound: {
            id: "",
            url: "",
            item: "",
            category: "",
            contentType: "",
          },
          locations: [],
          supportedOperations: { remoteCancel: false },
          parameters: [],
          priorityOverride: [],
        };

        const [site, setSite] = useState<string>("");
        const [locationTree, setLocationTree] = useState<ILocationTreeNode[]>([]);
        const [eventTemplate, setEventTemplate] = useState<IEventTemplate>(emptyEventTemplate);
        const [libraryItems, setLibraryItems] = useState<ILibraryItem[]>([]);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = {
          paddingBottom: "1rem",
        };
        const formPaddingStyleFlex: React.CSSProperties = {
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          top: "-14px",
        };
        const areaWrapperPaddingStyle: React.CSSProperties = {
          border: "1px solid rgb(0,0,0,0.42)",
          padding: "6px 10px 0 15px",
          borderRadius: "4px",
          maxHeight: "43px",
          marginBottom: "1rem",
        };
        const areaWrapperPaddingDynamicHeightStyle: React.CSSProperties = {
          border: "1px solid rgb(0,0,0,0.42)",
          padding: "6px 10px 0 15px",
          borderRadius: "4px",
          marginBottom: "1rem",
        };
        const labelStyle: React.CSSProperties = {
          fontSize: "12px",
          position: "relative",
          top: "-16px",
          zIndex: "2",
          paddingLeft: "2px",
          paddingRight: "2px",
          backgroundColor: "white",
          color: "rgba(148,148,148,.87)",
        };

        React.useEffect(() => {
          setSite(api.getData("currentSite"));
          refreshLibraryItemsData();

          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedEventTemplate") {
              await refreshData(value);
              console.debug(`New selectedEventTemplate is "${value}"`);
            }

            if (name === "currentSite") {
              console.debug("Updated site:", value);
              setSite(value);
            }

            if (name === "currentTenant") {
              console.debug("Updated tenant", value);
              refreshLibraryItemsData();
              loadLocations();
            }
          });
        }, []);

        React.useEffect(() => {
          // When the site is updated, we need to load the location tree, since the "Create new" form needs the location tree.
          loadLocations(site);
        }, [site]);

        /**
         * Loads the location tree anew from the API.
         * @param siteId The site ID for which to load locations.
         */
        const loadLocations = async (siteId?: string) => {
          const result = await query<ISelectableLocation[]>(gqlLocations);
          if (!siteId) siteId = api.getData("currentSite");
          var filtered = siteId ? result.filter((x) => x.site.id === siteId) : result;
          var locationTree = buildTree<ILocationTreeNode>(
            filtered,
            (x) => x.id,
            (x) => x.parentId,
            (x) => x.children,
            (x, xs) => {
              x.children = xs;
            }
          );
          console.debug("Loaded locations, site:", siteId, "tree: ", locationTree);
          setLocationTree(locationTree);
        };

        /**
         * Updates the locations tree by marking the appropriate nodes as selected or not selected.
         * Also sets the proper value for isExpanded. For the read-only view, we need to expand the right nodes
         * so that all selected nodes are visible.
         * @param selectedLocationIds List of selected location IDs which will be shown as marked.
         */
        const refreshLocationTree = async (selectedLocationIds: string[]) => {
          setLocationTree((prevLocations) =>
            prevLocations.map((loc) => updateNodeRecursively(selectedLocationIds, loc))
          );
        };

        const refreshLibraryItemsData = async () => {
          const result = await query<ILibraryItem[]>(gqlLibraryItems);
          setLibraryItems(result);
        };

        const groupedIcons = new DataSource({
          store: {
            type: "array",
            data: libraryItems.filter((l) => l.contentType.indexOf("image") > -1),
            key: "id",
          },
          group: "category",
        });

        const groupedSounds = new DataSource({
          store: {
            type: "array",
            data: libraryItems.filter((l) => l.contentType.indexOf("audio") > -1),
            key: "id",
          },
          group: "category",
        });

        /**
         * Loads the given event template, so we show fresh new data.
         * @param eventTemplateId The event template ID to load.
         */
        const refreshData = async (eventTemplateId) => {
          setEditMode(false);
          if (eventTemplateId === "") {
            setEventTemplate(emptyEventTemplate);
            return;
          }

          const result = await query<IEventTemplate[]>(gqlQueryEventTemplate, { eventTemplateId: [eventTemplateId] });
          if (result) {
            console.log("Refreshed data", result, "site", site);
            setEventTemplate(result[0]);
            await loadLocations(result[0].site.id);
            const selectedLocationIds = result[0].locations.map((l) => l.id);
            refreshLocationTree(selectedLocationIds);
          }
          setDeleteInProgress(false);
        };

        const createInputObject = (eventTemplate) => {
          const itemToSave: any = { ...eventTemplate };

          itemToSave.iconId = itemToSave.icon.id;
          itemToSave.soundId = itemToSave.sound.id;
          itemToSave.locations = eventTemplate.locations.map((l) => l.id as string);

          delete itemToSave.id;
          delete itemToSave.supportedOperations.__typename;
          delete itemToSave.icon;
          delete itemToSave.sound;
          delete itemToSave.__typename;
          delete itemToSave.site;
          delete itemToSave.dependencies;
          itemToSave.parameters.forEach((p) => {
            delete p.__typename;
          });
          itemToSave.priorityOverride.forEach((p) => {
            delete p.__typename;
            p.iconId = p.icon?.id;
            delete p.icon;
            p.soundId = p.sound?.id;
            delete p.sound;
          });

          return itemToSave;
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const itemToSave = createInputObject(eventTemplate);

            if (eventTemplate.id === "*") {
              const result = await mutation<IEventTemplate>(gqlAddEventTemplate, { input: itemToSave });

              if (result) {
                setEventTemplate(result);
                api.emit("refresh", { name: "eventTemplate", value: result.id });
                setEditMode(false);
              } else {
                api.showNotification("Error adding site", { type: "error" });
              }

              return;
            }

            const result = await mutation<IEventTemplate>(gqlUpdateEventTemplate, {
              id: eventTemplate.id,
              input: itemToSave,
            });
            if (result) {
              setEventTemplate(result);
              api.emit("refresh", { name: "eventTemplate", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating site", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          var newEventTemplate = { ...eventTemplate };

          switch (field) {
            case "iconId":
              var newEventTemplate = { ...eventTemplate };
              newEventTemplate.icon = { ...libraryItems.find((item) => item.id === e) };
              break;
            case "soundId":
              var newEventTemplate = { ...eventTemplate };
              newEventTemplate.sound = { ...libraryItems.find((item) => item.id === e) };
              break;
            case "remoteCancel":
              newEventTemplate.supportedOperations.remoteCancel = e;
              break;
            default:
              newEventTemplate[field] = e;
              break;
          }

          setEventTemplate(newEventTemplate);
        };

        const onAddClick = () => {
          setEditMode(true);
          refreshLocationTree([]);
          const selectedSite: ISite = { id: site, dependencies: [], name: "" };
          setEventTemplate({ ...emptyEventTemplate, id: "*", site: selectedSite });
        };

        if (eventTemplate.id === "")
          return (
            <>
              <div style={{ flexGrow: 1 }}></div>
              {site !== "*" ? (
                <div style={{ padding: "0.75rem 1rem" }}>
                  <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                    <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>Add New
                  </Button>
                </div>
              ) : (
                <div>To add an event template select a site.</div>
              )}
            </>
          );

        const renderColor = (e) => {
          return (
            <>
              <span
                style={{
                  backgroundColor: colorTranslation(e),
                  width: "25px",
                  display: "inline-block",
                  borderRadius: "4px",
                }}
              >
                &nbsp;
              </span>
              <span style={{ paddingLeft: "5px" }}>{colors.find((c) => c.id == e)?.name}</span>
            </>
          );
        };

        const imageUrl = (e) => {
          if (e != undefined) return e + "&width=25";

          return e;
        };

        const addParameter = (parameter: IParameter) => {
          var group = validationEngine.getGroupConfig("parameter");
          if (!group.validate().isValid) return false;

          var newEventTemplate = { ...eventTemplate };
          newEventTemplate.parameters.push(parameter);
          setEventTemplate(newEventTemplate);
          return true;
        };

        const addPriorityOverride = (priorityOverride) => {
          var group = validationEngine.getGroupConfig("priorityOverride");
          if (!group.validate().isValid) return false;

          priorityOverride.icon = libraryItems.find((item) => item.id === priorityOverride.iconId);
          priorityOverride.sound = libraryItems.find((item) => item.id === priorityOverride.soundId);
          delete priorityOverride.soundId;
          delete priorityOverride.iconId;

          var newEventTemplate = { ...eventTemplate };
          newEventTemplate.priorityOverride.push(priorityOverride);
          setEventTemplate(newEventTemplate);
          return true;
        };

        const onRemovePriorityOverride = (po) => {
          var newEventTemplate = { ...eventTemplate };
          newEventTemplate.priorityOverride.splice(
            newEventTemplate.priorityOverride.findIndex((p) => p.priority == po.priority),
            1
          );
          setEventTemplate(newEventTemplate);
        };

        const onRemoveParameter = (parameter) => {
          var newEventTemplate = { ...eventTemplate };
          newEventTemplate.parameters.splice(
            newEventTemplate.parameters.findIndex((p) => p.name == parameter.name),
            1
          );
          setEventTemplate(newEventTemplate);
        };

        const onLocationSelection = (e: ItemSelectionChangedEvent) => {
          if (editMode)
            setEventTemplate({
              ...eventTemplate,
              locations: e.component.getSelectedNodeKeys().map((key) => {
                return { id: key, name: "" } as ILocation;
              }),
            });
        };

        const deleteEventTemplate = async () => {
          setDeleteInProgress(true);
          const result = await deleteEntityGeneric<IEventTemplate>(
            api,
            eventTemplate.id,
            "eventTemplate",
            gqlQueryEventTemplateDependencies,
            gqlDeleteEventTemplate
          );

          if (result === true) {
            setEventTemplate(emptyEventTemplate);
          }
          setDeleteInProgress(false);
        };

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRow
                  editMode={editMode}
                  onCancel={() => {
                    if (eventTemplate.id == "*") {
                      setEventTemplate({ ...emptyEventTemplate });
                    } else {
                      refreshData(eventTemplate.id);
                    }

                    setEditMode(false);
                  }}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteEventTemplate()}
                  deleteInProgress={deleteInProgress}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                  addVisible={site !== "*"}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChange={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={eventTemplate.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <SelectBox
                    readOnly={!editMode}
                    label="Priority"
                    onValueChange={(e) => handleValueChanged(e, "priority")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={eventTemplate.priority}
                    items={priorities}
                    valueExpr="id"
                    displayExpr="name"
                  >
                    <Validator>
                      <RequiredRule message="Priority is required" />
                    </Validator>
                  </SelectBox>
                </div>
                <div style={formPaddingStyle}>
                  <SelectBox
                    readOnly={!editMode}
                    label="Color"
                    onValueChange={(e) => handleValueChanged(e, "color")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={eventTemplate.color}
                    items={colors}
                    itemRender={Color}
                    fieldRender={ColorField}
                    valueExpr="id"
                    displayExpr="name"
                  >
                    <Validator>
                      <RequiredRule message="Color is required" />
                    </Validator>
                  </SelectBox>
                </div>
                <div style={formPaddingStyle}>
                  <SelectBox
                    readOnly={!editMode}
                    label="Icon"
                    onValueChange={(e) => handleValueChanged(e, "iconId")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={eventTemplate.icon.id}
                    grouped={true}
                    dataSource={groupedIcons}
                    itemRender={Icon}
                    fieldRender={IconField}
                    valueExpr="id"
                    displayExpr="item"
                  >
                    <Validator>
                      <RequiredRule message="Icon is required" />
                    </Validator>
                  </SelectBox>
                </div>
                <div style={formPaddingStyle}>
                  <SelectBox
                    readOnly={!editMode}
                    label="Sound"
                    onValueChange={(e) => handleValueChanged(e, "soundId")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={eventTemplate.sound.id}
                    grouped={true}
                    dataSource={groupedSounds}
                    itemRender={Icon}
                    fieldRender={IconField}
                    valueExpr="id"
                    displayExpr="item"
                  >
                    <Validator>
                      <RequiredRule message="Sound is required" />
                    </Validator>
                  </SelectBox>
                </div>
                <div style={areaWrapperPaddingStyle}>
                  <span style={labelStyle}>Supported operations</span>
                  <div style={formPaddingStyleFlex}>
                    <div>Remote cancel</div>
                    <div>
                      <Switch
                        readOnly={!editMode}
                        onValueChange={(e) => handleValueChanged(e, "remoteCancel")}
                        stylingMode="outlined"
                        value={eventTemplate.supportedOperations.remoteCancel}
                      ></Switch>
                    </div>
                  </div>
                </div>
                <div style={areaWrapperPaddingDynamicHeightStyle}>
                  <span style={labelStyle}>Parameters</span>
                  <div style={formPaddingStyle}>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Name</th>
                          <th style={{ textAlign: "left" }}>Type</th>
                          <th style={{ textAlign: "left" }}>Constraints</th>
                          <th style={{ textAlign: "left" }}>Format</th>
                          <th style={{ textAlign: "left" }}>Default</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventTemplate.parameters.map((parameter) => (
                          <tr key={parameter.name}>
                            <td>{parameter.name}</td>
                            <td>{parameter.constraintsType}</td>
                            <td>{parameter.constraints}</td>
                            <td>{parameter.format}</td>
                            <td>{parameter.defaultValue}</td>
                            <td>
                              <Button
                                icon="trash"
                                visible={editMode}
                                onClick={() => onRemoveParameter(parameter)}
                              ></Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Button
                      visible={editMode}
                      text="Outlined"
                      type="normal"
                      stylingMode="outlined"
                      onClick={() =>
                        api.showModal(eventTemplateAddParametersModal, {
                          addParameter,
                          parameters: eventTemplate.parameters,
                        })
                      }
                    >
                      Add parameter
                    </Button>
                  </div>
                </div>
                <div style={areaWrapperPaddingDynamicHeightStyle}>
                  <span style={labelStyle}>Priority Overrides</span>
                  <div style={formPaddingStyle}>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Priority</th>
                          <th style={{ textAlign: "left" }}>Color</th>
                          <th style={{ textAlign: "left" }}>Sound</th>
                          <th style={{ textAlign: "left" }}>Icon</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventTemplate.priorityOverride.map((priorityOverride) => (
                          <tr key={priorityOverride.priority}>
                            <td>{priorities.find((p) => p.id == priorityOverride.priority)?.name}</td>
                            <td>{renderColor(priorityOverride.color)}</td>
                            <td>
                              {priorityOverride.sound ? (
                                <>
                                  <span style={{ display: "inline-block" }}>
                                    <img style={{ width: "25px", height: "25px" }} src={SoundIcon} />
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "top",
                                      marginTop: "5px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {priorityOverride.sound?.category}:{priorityOverride.sound?.item}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {priorityOverride.icon ? (
                                <>
                                  <span style={{ display: "inline-block" }}>
                                    <img
                                      style={{ width: "25px", height: "25px" }}
                                      src={imageUrl(priorityOverride.icon?.url)}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "top",
                                      marginTop: "5px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {priorityOverride.sound?.category}:{priorityOverride.sound?.item}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              <Button
                                icon="trash"
                                visible={editMode}
                                onClick={() => onRemovePriorityOverride(priorityOverride)}
                              ></Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Button
                      visible={editMode}
                      text="Outlined"
                      type="normal"
                      stylingMode="outlined"
                      onClick={() =>
                        api.showModal(eventTemplateAddPriorityOverrideModal, {
                          addPriorityOverride,
                          priorities,
                          priority: eventTemplate.priority,
                          colors,
                          groupedIcons,
                          groupedSounds,
                          priorityOverrides: eventTemplate.priorityOverride,
                        })
                      }
                    >
                      Add priority override
                    </Button>
                  </div>
                </div>
                <div style={areaWrapperPaddingDynamicHeightStyle}>
                  <span style={labelStyle}>Locations</span>
                  <div style={formPaddingStyle}>
                    <TreeView
                      items={locationTree}
                      dataStructure="tree"
                      selectedItemKeys={eventTemplate.locations.map((l) => l.id)}
                      keyExpr="id"
                      expandedExpr="isExpanded"
                      disabled={!editMode}
                      selectedExpr="selected"
                      itemsExpr="children"
                      selectionMode="multiple"
                      displayExpr="name"
                      searchEnabled={editMode}
                      searchMode="contains"
                      selectNodesRecursive={false}
                      showCheckBoxesMode="normal"
                      onItemClick={(e: ItemClickEvent) => {
                        if (!editMode) e.event.preventDefault();
                      }}
                      onItemSelectionChanged={onLocationSelection}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "eventtemplate", sortOrder: 1, name: "Event template properties", icon: InfoIcon }
    );

    api.registerModal(eventTemplateAddParametersModal, ({ onClose, options }) => {
      let opts = options as IAddParametersModalOptions;
      const formPaddingStyle: React.CSSProperties = {
        paddingBottom: "1rem",
      };

      const [parameter, setParameter] = useState<IParameter>({
        name: "",
        constraintsType: "IntRange",
        constraints: "",
        format: "int",
        defaultValue: "",
      });

      const handleValueChanged = async (e, field) => {
        var newParameter = { ...parameter };
        newParameter[field] = e;
        setParameter(newParameter);
      };

      const nameValidation = (e) => {
        if (opts.parameters.find((p) => p.name === e.value)) return false;

        return true;
      };

      return (
        <div className="modal-container">
          <div className="modal" style={{ height: 460 }}>
            <div className="modal-header">
              <div className="modal-header-text">Add Parameter</div>
              <div className="modal-header-right">
                <a className="modal-button-close" onClick={onClose} data-testid="aboutCloseButton"></a>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div style={{ padding: "0 1rem 1rem 1rem" }}>
                  <div style={formPaddingStyle}>
                    <TextBox
                      label="Name"
                      onValueChange={(e) => handleValueChanged(e, "name")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={parameter.name}
                    >
                      <Validator validationGroup="parameter">
                        <CustomRule
                          validationCallback={nameValidation}
                          message="A parameter with that name already exists"
                        ></CustomRule>
                        <RequiredRule message="Name is required" />
                        <StringLengthRule max={15} message="Max 15 characters" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Constraints type"
                      items={["IntRange", "StringRange", "regex"]}
                      onValueChange={(e) => handleValueChanged(e, "constraintsType")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={parameter.constraintsType}
                    ></SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <TextBox
                      label="Constraints"
                      onValueChange={(e) => handleValueChanged(e, "constraints")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={parameter.constraints}
                    ></TextBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Format"
                      items={["string", "int", "boolean"]}
                      onValueChange={(e) => handleValueChanged(e, "format")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={parameter.format}
                    ></SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <TextBox
                      label="Default value"
                      onValueChange={(e) => handleValueChanged(e, "defaultValue")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={parameter.defaultValue}
                    ></TextBox>
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
                    <Button
                      text="Outlined"
                      type="normal"
                      stylingMode="outlined"
                      onClick={(e) => {
                        if (opts.addParameter(parameter)) onClose();
                      }}
                    >
                      Add parameter
                    </Button>
                    <Button text="Outlined" type="normal" stylingMode="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    });

    api.registerModal(eventTemplateAddPriorityOverrideModal, ({ onClose, options }) => {
      let opts = options as IAddPriorityOverrideModalOptions;

      const formPaddingStyle: React.CSSProperties = {
        paddingBottom: "1rem",
      };

      const modalStyle: React.CSSProperties = {
        width: 450,
        height: 450,
        backgroundColor: "#FFF",
        margin: "auto",
        boxShadow: "-6px 6px 5px 0px #a19f9e",
      };

      const [priorityOverride, setPriorityOverride] = useState<IPriorityOverrideInput>({
        priority: "",
        color: null,
        iconId: null,
        soundId: null,
      });

      const handleValueChanged = async (e, field) => {
        var newPriorityOverride = { ...priorityOverride };
        newPriorityOverride[field] = e;
        setPriorityOverride(newPriorityOverride);
      };

      const priorityValidation = (e) => {
        if (opts.priorityOverrides.find((p) => p.priority === e.value)) return false;

        if (opts.priority == e.value) return false;

        return true;
      };

      return (
        <div className="modal-container">
          <div className="modal" style={{ height: 400 }}>
            <div className="modal-header">
              <div className="modal-header-text">Add priority override</div>
              <div className="modal-header-right">
                <a className="modal-button-close" onClick={onClose} data-testid="aboutCloseButton"></a>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div style={{ padding: "0 1rem 1rem 1rem" }}>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Priority"
                      onValueChange={(e) => handleValueChanged(e, "priority")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={priorityOverride.priority}
                      items={opts.priorities}
                      valueExpr="id"
                      displayExpr="name"
                    >
                      <Validator validationGroup="priorityOverride">
                        <CustomRule
                          message="Only one override per priority"
                          validationCallback={priorityValidation}
                        ></CustomRule>
                        <RequiredRule message="Priority is required" />
                      </Validator>
                    </SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Color"
                      onValueChange={(e) => handleValueChanged(e, "color")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={priorityOverride.color}
                      items={opts.colors}
                      itemRender={Color}
                      fieldRender={ColorField}
                      valueExpr="id"
                      displayExpr="name"
                    ></SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Icon"
                      onValueChange={(e) => handleValueChanged(e, "iconId")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={priorityOverride.iconId}
                      grouped={true}
                      dataSource={opts.groupedIcons}
                      itemRender={Icon}
                      fieldRender={IconField}
                      valueExpr="id"
                      displayExpr="item"
                    ></SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Sound"
                      onValueChange={(e) => handleValueChanged(e, "soundId")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={priorityOverride.soundId}
                      grouped={true}
                      dataSource={opts.groupedSounds}
                      itemRender={Icon}
                      fieldRender={IconField}
                      valueExpr="id"
                      displayExpr="item"
                    ></SelectBox>
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
                    <Button
                      text="Outlined"
                      type="normal"
                      stylingMode="outlined"
                      onClick={(e) => {
                        if (opts.addPriorityOverride(priorityOverride)) onClose();
                      }}
                    >
                      Add priority override
                    </Button>
                    <Button text="Outlined" type="normal" stylingMode="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    });
  },
};
