import { Button, LoadIndicator } from "devextreme-react";
import * as React from "react";
import EditIcon from "../../assets/icons/ic_edit_24px.svg";

const EditButtonRow = ({
  editMode,
  onCancel,
  onEdit,
  onDelete,
  onSave,
  onAdd,
  deleteInProgress,
  addVisible = true,
}) => {
  const btnWidthPx = 116;

  return (
    <div style={{ padding: "0.75rem 1rem" }}>
      <Button
        visible={!editMode && addVisible}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onAdd}
      >
        <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>
        Add new
      </Button>
      <Button
        visible={editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onCancel}
      >
        <span className="dx-icon-close" style={{ fontSize: "18px" }}></span>
        Cancel
      </Button>
      &nbsp;
      <Button
        visible={!editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onEdit}
      >
        <img src={EditIcon} style={{ width: "18px" }} />
        Edit
      </Button>
      &nbsp;
      <Button
        visible={!editMode}
        width={btnWidthPx}
        disabled={deleteInProgress}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onDelete}
      >
        <div style={{ marginRight: 10, display: deleteInProgress ? "block" : "none" }}>
          <LoadIndicator height={16} width={16} />
        </div>
        <span className="dx-icon-trash" style={{ fontSize: "18px" }}></span>
        Delete
      </Button>
      &nbsp;
      <Button
        visible={editMode}
        width={btnWidthPx}
        text="Outlined"
        type="normal"
        stylingMode="outlined"
        onClick={onSave}
      >
        <span className="dx-icon-floppy" style={{ fontSize: "18px" }}></span>
        Save
      </Button>
    </div>
  );
};

export default EditButtonRow;
