import { GenericOkButtonPiletName } from "../../Components/Modals/GenericOkButtonModal";
import { client, instance } from "../../index";
import { CombinedError } from "./CombinedError";

export async function query<T>(query: graphQLQuery, variables?: object): Promise<T> {
  try {
    const result = variables
      ? await client.query(query.query, variables).toPromise()
      : await client.query(query.query).toPromise();

    if (!result.data) throw new Error(result.error.message);

    return getData(result.data, query.collection);
  } catch (error) {
    instance.root.showNotification(query.errorMessage, { type: "error" });
    console.error("Error loading data:", error);
  }
}

export async function mutation<T>(mutation: graphQLQuery, variables: object): Promise<T> {
  try {
    const result = variables
      ? await client.mutation(mutation.query, variables).toPromise()
      : await client.mutation(mutation.query).toPromise();

    if (result.data) {
      return getData(result.data, mutation.collection);
    }

    const err: CombinedError = result.error as any;

    if (err?.graphQLErrors?.some((e) => e.extensions.code === "CANNOT_DELETE_RELATED_ENTITY")) {
      instance.root.showModal(GenericOkButtonPiletName, {
        title: "Delete failed",
        body: err.graphQLErrors[0].message,
        onClose: () => {},
      });
    } else if (err?.graphQLErrors?.some((e) => e.extensions.code === "CANNOT_UPDATE_RELATED_ENTITY")) {
      instance.root.showModal(GenericOkButtonPiletName, {
        title: "Update failed",
        body: err.graphQLErrors[0].message,
        onClose: () => {},
      });
    } else {
      instance.root.showNotification(mutation.errorMessage, { type: "error" });
      console.error("Error during mutation:", err.graphQLErrors, mutation.errorMessage);
    }
  } catch (error) {
    instance.root.showNotification(mutation.errorMessage, { type: "error" });
    console.error("Unhandled error during mutation:", error, mutation.errorMessage);
  }
}

function getData(obj: any, path: string): any {
  const levels = path.split(".");
  let value = obj;

  for (const level of levels) {
    value = Reflect.get(value, level);
    if (value === undefined) {
      break;
    }
  }

  return value;
}

export interface graphQLQuery {
  query: string;
  errorMessage: string;
  collection: string;
}
