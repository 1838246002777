import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { gqlAddTenant, gqlDeleteTenant, gqlTenant, gqlUpdateTenant } from "./TenantQuery";
import { mutation, query } from "../../Services/GraphQL";
import TextBox from "devextreme-react/text-box";
import { Validator, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import Button from "devextreme-react/button";
import {
  IConfirmDeleteModalOptions,
  confirmDeletionModalName,
  shouldShowDeleteConfirmationModal,
} from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ITenant } from "./ITenant";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";
import { getApiRealBaseUrl } from "../../Services/config";
import { IDependency } from "../Devices/IDependency";

export const TenantPropertiesPilet: Pilet = {
  name: "Tenant Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "tenant-properties",
      ({ params }) => {
        const emptyTenant: ITenant = {
          id: "",
          name: "",
          settings: [],
        };
        const [tenant, setTenant] = useState<ITenant>(emptyTenant);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedTenant") {
              await refreshData(value);
            }
          });
        }, []);

        const refreshData = async (tenantId) => {
          setEditMode(false);
          setDeleteInProgress(false);

          if (tenantId === "") {
            setTenant(emptyTenant);
            return;
          }

          const result = await query(gqlTenant, { tenantId: [tenantId] });
          if (result) setTenant(result[0]);
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const tenantToSave = {
              name: tenant.name,
            };

            if (tenant.id === "*") {
              const result = await mutation<ITenant>(gqlAddTenant, { input: tenantToSave });

              if (result) {
                setTenant(result);
                api.emit("refresh", { name: "tenants", value: result.id });
                setEditMode(false);
              } else {
                api.showNotification("Error adding tenant", { type: "error" });
              }

              return;
            }

            const result = await mutation<ITenant>(gqlUpdateTenant, { id: tenant.id, input: tenantToSave });
            if (result) {
              setTenant(result);
              api.emit("refresh", { name: "tenants", value: result.id });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating tenant", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newTenant = { ...tenant };
            newTenant[field] = e.value;
            setTenant(newTenant);
          }
        };

        const deleteTenant = async () => {
          console.debug("Deleting tenant", tenant.id);

          const result = await mutation<ITenant>(gqlDeleteTenant, {
            input: { id: tenant.id },
          });
          if (result) {
            console.log("Deleted tenant", tenant.id);
            setTenant(emptyTenant);
            api.emit("refresh", { name: "tenants", value: result.id });
          } else {
            api.showNotification("Error deleting tenant", {
              type: "error",
            });
          }
        };

        const onAddClick = () => {
          setEditMode(true);
          setTenant({ ...emptyTenant, id: "*" });
        };

        if (tenant.id === "")
          return (
            <>
              <div style={{ flexGrow: 1 }}></div>
              <div style={{ padding: "0.75rem 1rem" }}>
                <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                  <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>Add New
                </Button>
              </div>
            </>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRow
                  editMode={editMode}
                  onCancel={() => {
                    if (tenant.id == "*") {
                      setTenant({ ...emptyTenant });
                    } else {
                      refreshData(tenant.id);
                    }

                    setEditMode(false);
                  }}
                  deleteInProgress={deleteInProgress}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteTenant()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>TenantId: {tenant.id}</div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChanged={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={tenant.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={50} message="Max 50 characters" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "tenant", sortOrder: 1, name: "Tenant properties", icon: InfoIcon }
    );
  },
};
