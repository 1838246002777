import { Pilet, PiletApi } from "piral-base";
import React, { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import SettingIcon from "../../assets/icons/Group.svg";
import {
  gqlAddTenantSetting,
  gqlRemoveTenantSetting,
  gqlTenantSettings,
  gqlTenantSettingsConcurrencyCheck,
  gqlTenantSystems,
} from "../Tenants/TenantQuery";
import Button from "devextreme-react/button";
import EditIcon from "../../assets/icons/ic_edit_24px.svg";
import { IAddSettingsModalOptions, ISetting, SettingListProps, SettingOperation } from "./ISetting";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";

import { isNullOrWhitespace } from "../../util/nullUtilities";
import { GenericOkButtonPiletName } from "../../Components/Modals/GenericOkButtonModal";

const SettingList: React.FC<SettingListProps> = ({
  settings,
  systems,
  entityId,
  api,
  onSave,
  onConcurrencyError,
  onCancel,
}) => {
  const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };
  const [data, setData] = useState<ISetting[]>(settings);
  const [editMode, setEditMode] = useState<boolean>(false);

  React.useEffect(() => {
    setEditMode(false);
    const list: ISetting[] = [];
    settings.forEach((i) => list.push({ ...i, operation: SettingOperation.None }));
    setData(list);
  }, [settings]);

  const onRemove = (setting: ISetting) => {
    const index = data.indexOf(setting);
    if (index > -1) {
      data[index].operation = SettingOperation.Remove;
      setData([...data]);
    }
  };

  const addSetting = (setting: ISetting) => {
    if (data.some((x) => x.settingKey.toLowerCase() === setting.settingKey.toLowerCase())) {
      console.info("Tried to add setting with system which is already in use:", setting);
      api.showModal(GenericOkButtonPiletName, {
        title: "Could not add setting",
        body: "An setting with the same system already exists, please choose a different system.",
        onClose: () => {},
      });
      return;
    }

    setData([...data, setting]);
    return true;
  };

  if (isNullOrWhitespace(entityId)) {
    return <span>Select an item to continue.</span>;
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ padding: "0.75rem 1rem" }}>
          <Button
            visible={editMode}
            width={120}
            text="Outlined"
            type="normal"
            stylingMode="outlined"
            onClick={() => {
              onCancel();
              setEditMode(false);
            }}
          >
            <span className="dx-icon-close" style={{ fontSize: "18px" }}></span>Cancel
          </Button>
          &nbsp;
          <Button
            visible={!editMode}
            width={120}
            text="Outlined"
            type="normal"
            stylingMode="outlined"
            onClick={() => setEditMode(true)}
          >
            <img src={EditIcon} style={{ width: "18px" }} />
            Edit
          </Button>
          <Button
            visible={editMode}
            width={120}
            text="Outlined"
            type="normal"
            stylingMode="outlined"
            onClick={() => {
              onSave(data);
              setEditMode(false);
            }}
            icon="floppy"
          >
            <span className="dx-icon-floppy" style={{ fontSize: "18px" }}></span>Save
          </Button>
        </div>
      </div>
      <div style={formPaddingStyle}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Key</th>
              <th style={{ textAlign: "left" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((i) => i.operation !== SettingOperation.Remove)
              .map((setting, index) => {
                return (
                  <tr key={index}>
                    <td height={36}>{setting.settingKey}</td>
                    <td>{setting.value}</td>
                    <td width={40}>
                      <Button icon="trash" visible={editMode} onClick={() => onRemove(setting)}></Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Button
          visible={editMode}
          text="Outlined"
          type="normal"
          stylingMode="outlined"
          onClick={() => api.showModal("add-setting-modal", { addSetting, systems, onConcurrencyError })}
        >
          Add setting
        </Button>
      </div>
    </div>
  );
};

export const SettingPilet: Pilet = {
  name: "Setting Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerModal("add-setting-modal", ({ options, onClose }) => {
      let opts = options as IAddSettingsModalOptions;
      const formPaddingStyle: React.CSSProperties = {
        paddingBottom: "1rem",
      };

      const [setting, setSetting] = useState<ISetting>({
        id: "",
        settingKey: "",
        value: "",
        operation: SettingOperation.Add,
      });
      const [systems, setSystems] = useState<string[]>([]);

      React.useEffect(() => {
        setSystems(opts.systems);
      }, [opts.systems]);

      const onNewSystem = async (e: any) => {
        var newSystems = [...systems];
        newSystems.push(e.text);
        setSystems(newSystems);

        var newSetting = { ...setting };
        newSetting.settingKey = e.text;
        setSetting(newSetting);
      };

      const handleValueChanged = async (e, field) => {
        var newSetting = { ...setting };
        newSetting[field] = e;
        setSetting(newSetting);
      };

      const addSetting = async () => {
        //if (!(await opts.onConcurrencyError(setting))) {
        if (opts.addSetting(setting)) {
          onClose();
        }
        // } else {
        //   alert("Concurrency error");
        // }
      };

      return (
        <div className="modal-container">
          <div className="modal" style={{ height: 460 }}>
            <div className="modal-header">
              <div className="modal-header-text">Add Setting</div>
              <div className="modal-header-right">
                <a className="modal-button-close" onClick={onClose} data-testid="aboutCloseButton"></a>
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div style={{ padding: "0 1rem 1rem 1rem" }}>
                  <div style={formPaddingStyle}>
                    <SelectBox
                      label="Key"
                      items={systems}
                      onValueChange={(e) => handleValueChanged(e, "settingKey")}
                      labelMode="floating"
                      stylingMode="outlined"
                      acceptCustomValue={true}
                      onCustomItemCreating={onNewSystem}
                      value={setting.settingKey}
                    ></SelectBox>
                  </div>
                  <div style={formPaddingStyle}>
                    <TextBox
                      label="Value"
                      onValueChange={(e) => handleValueChanged(e, "value")}
                      labelMode="floating"
                      stylingMode="outlined"
                      value={setting.value}
                    ></TextBox>
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
                    <Button
                      disabled={isNullOrWhitespace(setting?.settingKey)}
                      text="Outlined"
                      type="normal"
                      stylingMode="outlined"
                      onClick={addSetting}
                    >
                      Add setting
                    </Button>
                    <Button text="Outlined" type="normal" stylingMode="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    });

    api.registerExtension(
      "tenant-settings",
      ({ params }) => {
        const [tenantId, setTenantId] = useState<string>("");
        const [settings, setSettings] = useState([]);
        const [systems, setSystems] = useState([]);

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedTenant" && value != "") {
              setTenantId(value);
              await refreshData(value);
              console.log(`New value is "${value}"!`);
            }
          });
        }, []);

        const refreshData = async (tenantId) => {
          const result = await query<any>(gqlTenantSettings, { tenantId });
          setSettings(result[0].settings);

          //const result2 = await query<any>(gqlTenantSystems, { tenantId });
          //setSystems(result2.map((s) => s.system));
          setSystems(["UniteUrl"]);
        };

        const onCancel = async () => {
          await refreshData(tenantId);
        };

        const onConcurrencyError = async (tenantId) => {
          const result = await query<any>(gqlTenantSettings, {
            tenantId: tenantId,
          });
          return result.length > 0;
        };

        const onSave = async (i: ISetting[]) => {
          let result: any = {};

          for (let id of i) {
            if (id.operation == SettingOperation.Add) {
              result = await mutation<any>(gqlAddTenantSetting, {
                input: { id: tenantId, setting: { settingKey: id.settingKey, value: id.value } },
              });
            }

            if (id.operation == SettingOperation.Remove) {
              result = await mutation<any>(gqlRemoveTenantSetting, {
                input: { id: tenantId, settingKey: id.settingKey },
              });
            }
          }

          setSettings(result.settings);
        };

        return (
          <SettingList
            settings={settings}
            systems={systems}
            entityId={tenantId}
            api={api}
            onCancel={onCancel}
            onSave={(i) => onSave(i)}
            onConcurrencyError={(tenantId) => onConcurrencyError(tenantId)}
          />
        );
      },
      { type: "tenant", sortOrder: 3, name: "Settings", icon: SettingIcon }
    );
  },
};
